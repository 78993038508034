import React, { useEffect, useState } from "react";
import classNames from "classnames";
import SignOutBtn from "../SignOutBtn/SignOutBtn";
// import { db } from "../../../firebase";
// import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import DeleteConfirmation from "../AddClientCompononents/DeleteConfirmation";
import AddCategory from "./AddCategory";
import ErrorMsg from "../AddProductComponents/ErrorMsg";
import AddSubCategory from "./AddSubCategory";

import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { DELETE_CATEGORY_API, DELETE_SUBCATEGORY_API, FETCH_CATEGORIES_API, FETCH_SUBCATEGORIES_API } from "../../Api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const authToken = localStorage.getItem("authToken");

const AddCategories = () => {
  const [categoryName, setcategoryName] = useState(null);
  const [subCategoryName, setsubCategoryName] = useState(null);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const tempCategoryData = [];
  const tempSubCategoryData = [];

  const [errMsgPopUp, setErrMsgPopUp] = useState(false); // Error Pop up message
  const [subCatErrMsgPopUp, setSubCatErrMsgPopUp] = useState(false); // Error Pop up message
  const [selectedCatBtnIndex, setselectedCatBtnIndex] = useState(null);
  const [selectedSubCatBtnIndex, setselectedSubCatBtnIndex] = useState(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [subCatDeleteConfirmation, setSubCatDeleteConfirmation] = useState(false);

  const [addCategoryBtn, setAddCategoryBtn] = useState(false); // Add category button
  const [addSubCategoryBtn, setAddSubCategoryBtn] = useState(false); // Add sub-category button

  //  check if user is authenticated
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, []);
  
  //   styling for the selected buttons
  const getCategoryClasses = (index) =>
    classNames("cursor-pointer", {
      // "bg-green-200": selectedCatBtnIndex == index,
      // "bg-gray-100":
      //   selectedCatBtnIndex !== null && selectedCatBtnIndex !== index,
      "font-bold": selectedCatBtnIndex == index,
      "font-normal":
        selectedCatBtnIndex !== null && selectedCatBtnIndex !== index,
    });

  const getSubCategoryClasses = (index) =>
    classNames("cursor-pointer", {
      // "bg-green-200": selectedSubCatBtnIndex == index,
      // "bg-gray-100":
      // selectedSubCatBtnIndex !== null && selectedSubCatBtnIndex !== index,
      "font-bold": selectedSubCatBtnIndex == index,
      "font-normal":
        selectedSubCatBtnIndex !== null && selectedSubCatBtnIndex !== index,
    });

  const handleAddCategoryBtn = () => {
    // if(categoryName == null){
    //   setErrMsgPopUp(true);
    //   return;
    // }
    setAddCategoryBtn(!addCategoryBtn);
  }

  const handleAddSubCategoryBtn = () => {
    setAddSubCategoryBtn(!addSubCategoryBtn);
  }
  const handleCategoryClickBtn = (index, category) => {
    if (selectedCatBtnIndex == index) {
      setselectedCatBtnIndex(null);
      setcategoryName(null);
      setSubCategories([]);
    } else {
      setselectedCatBtnIndex(index);
      setcategoryName(category);
    }
  };

  const handleSubCategoryClickBtn = (index, subCategory) => {
    if (selectedSubCatBtnIndex == index) {
      setselectedSubCatBtnIndex(null);
      setsubCategoryName(null);
    } else {
      setselectedSubCatBtnIndex(index);
      setsubCategoryName(subCategory);
    }
  };

  const handleCategoryDeleteBtn = () => {
    if (categoryName == null) {
      setErrMsgPopUp(true);
      return;
    }
    setDeleteConfirmation(!deleteConfirmation);
  };

  const handleSubCategoryDeleteBtn = () => {
    if (subCategoryName == null) {
      setSubCatErrMsgPopUp(true);
      return;
    }
    setSubCatDeleteConfirmation(!subCatDeleteConfirmation);
  };

  const deleteCategory = async () => {
    // const collectionRef = collection(db, "Categories");
    // const docRef = doc(collectionRef, categoryName.id);
    // try {
    //   deleteDoc(docRef)
    //     .then(() => {
    //       console.log("Cat Document successfully deleted!");
    //       setcategoryName(null);
    //       setDeleteConfirmation(false);
    //       fetchCategoriesFromServer();
    //     })
    //     .catch((error) => {
    //       console.error("Error removing category document: ", error);
    //     });
    // } catch (error) {
    //   console.error("Error:", error);
    // }

    try {
      // console.log("Auth:", authToken);
      const response = await axios.delete(DELETE_CATEGORY_API + categoryName.id
      //   ,{
      //   headers: {
      //     Authorization: `Bearer ${authToken}`,
      //   },
      // }
      );
      console.log(response);
      setcategoryName(null);
      setDeleteConfirmation(false);
      fetchCategoriesFromServer();
    }
    catch (error) {
      console.error("Error removing category document: ", error);
    }
  };

  const deleteSubCategory = async () => {
    console.log(
      "Inside deleteSubCategory: " + categoryName.id + " " + subCategoryName.id
    );
    // const collectionRef = collection(
    //   db,
    //   "Categories",
    //   categoryName.id,
    //   "SubCategories"
    // );
    // const docRef = doc(collectionRef, subCategoryName.id);
    // try {
    //   deleteDoc(docRef)
    //     .then(() => {
    //       console.log("Sub-cat Document successfully deleted!");
    //       setsubCategoryName(null);
    //       setSubCatDeleteConfirmation(false);
    //       fetchSubCategoriesFromServer();
    //     })
    //     .catch((error) => {
    //       console.error("Error removing sub-category document: ", error);
    //     });
    // } catch (error) {
    //   console.error("Error:", error);
    // }
    try {
      const response = await axios.delete(DELETE_SUBCATEGORY_API + categoryName.id + "/" + subCategoryName.id);
      console.log(response);
      setsubCategoryName(null);
      setSubCatDeleteConfirmation(false);
      fetchSubCategoriesFromServer();
    }
    catch (error) {
      console.error("Error removing sub-category document: ", error);
    }
  };

  //   Fetch categories from Firestore
  // async function fetchCategoriesFromFirestore() {
  //   const categoriesCollection = collection(db, "Categories"); // Reference to the 'categories' collection in Firestore

  //   try {
  //     const querySnapshot = await getDocs(categoriesCollection); // Fetch all documents in the 'products' collection

  //     querySnapshot.forEach((item) => {
  //       console.log(item);
  //       const categoryData = item.data();
  //       console.log(categoryData.name);
  //       tempCategoryData.push(categoryData);
  //     });
  //     setCategories(tempCategoryData);
  //     // Set the categories data in state
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // }
  async function fetchCategoriesFromServer() {  
    try {
      const response = await fetch(FETCH_CATEGORIES_API);
      const categoriesData = await response.json();
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

  //   Fetch sub-categories from Firestore
  // async function fetchSubCategoriesFromFirestore() {
  //   if (!categoryName) {
  //     return;
  //   }
  //   const subCategoriesCollection = collection(
  //     db,
  //     "Categories",
  //     categoryName.id,
  //     "SubCategories"
  //   ); // Reference to the 'categories' collection in Firestore
  //   try {
  //     console.log("Inside fetchSubCategoriesFromFirestore");
  //     const querySnapshot = await getDocs(subCategoriesCollection); // Fetch all documents in the 'products' collection

  //     querySnapshot.forEach((item) => {
  //       console.log("Item data: ", item);
  //       const subCategoryData = item.data();
  //       console.log(subCategoryData.name);
  //       tempSubCategoryData.push(subCategoryData);
  //     });
  //     setSubCategories(tempSubCategoryData);
  //     // Set the categories data in state
  //   } catch (error) {
  //     console.error("Error fetching sub-categories:", error);
  //   }
  // }
  
  async function fetchSubCategoriesFromServer() {
    if (!categoryName) {
      return;
    }
    try {
      const response = await fetch(FETCH_SUBCATEGORIES_API + categoryName.id);
      const subCategoriesData = await response.json();
      setSubCategories(subCategoriesData);
    } catch (error) {
      console.error('Error fetching sub-categories:', error);
    }
  }
  useEffect(() => {
    fetchCategoriesFromServer(); // Fetch categories when the component mounts
  }, []);

  useEffect(() => {
    fetchSubCategoriesFromServer(); // Fetch  sub-categories when the component mounts
  }, [categoryName]);

  return (
    <div>
      <SignOutBtn />
      {
        errMsgPopUp && (
          <ErrorMsg
            errMsg="Please select a category first."
            onClose={() => {
              setErrMsgPopUp(false);
            }}
          />
        )
      }
      {
        subCatErrMsgPopUp && (
          <ErrorMsg
            errMsg="Please select a sub-category first."
            onClose={() => {
              setSubCatErrMsgPopUp(false);
            }}
          />
        )
      }
      {
        // Delete confirmation popup
        deleteConfirmation && (
          <DeleteConfirmation
            onConfirm={() => {
              deleteCategory();
              setselectedCatBtnIndex(null);
            }}
            onCancel={() => setDeleteConfirmation(false)}
          />
        )
      }
      {
        // Delete confirmation popup
        subCatDeleteConfirmation && (
          <DeleteConfirmation
            onConfirm={() => {
              deleteSubCategory();
            }}
            onCancel={() => setSubCatDeleteConfirmation(false)}
          />
        )
      }
      {
        // Add category popup

        addCategoryBtn && ( 
          <AddCategory
            onClose={() => {
              setAddCategoryBtn(false);
              fetchCategoriesFromServer();
            }}
          />
        )
      }

      {
        // Add sub-category popup
        addSubCategoryBtn && (
          categoryName?(
            <AddSubCategory
              onClose={() => {
                setAddSubCategoryBtn(false);
                fetchSubCategoriesFromServer();
              }}
              categoryName={categoryName.id}
            />
          ):(
            <ErrorMsg 
            errMsg="Please select a category first."
            onClose={() => {
              setAddSubCategoryBtn(false);
            }}
            />
          )
        )
      }
      <div>
        <h2 className="mt-20 mb-10 text-center text-xl md:text-3xl font-extrabold text-gray-900">
          Add or Edit Categories, Sub-categories
        </h2>
      </div>
      <div className="Buttons lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-col items-center md:flex-row md:justify-around md:align-middle">
        {/* Add category Btn */}
        <div
          className=" m-1 my-2 md:my-0  px-2 py-2 w-3/6 md:w-1/6 flex flex-row justify-start md:justify-center items-center rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg  h-auto hover:cursor-default transform transition-all hover:scale-110"
            onClick={() => {
              handleAddCategoryBtn();
            }}
        >
          <IoIosAddCircleOutline className="md:hidden w-9" />
          <p className="text-center md:text-md">Add Category</p>
        </div>

        {/* Delete category Btn */}
        <div
          className={` m-1 my-2 md:my-0 px-2 py-2 w-3/6 md:w-1/6 flex flex-row justify-start md:justify-center items-center rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg h-auto hover:cursor-default transform transition-all hover:scale-110`}
          onClick={() => {
            handleCategoryDeleteBtn();
          }}
        >
          <MdDeleteOutline className="md:hidden w-9 " />
          <p className="text-center text-md">Delete Category</p>
        </div>

        {/* Delete category Btn */}
        <div
          className=" m-1 my-2 md:my-0 px-2 py-2 w-3/6 md:w-1/6 flex flex-row justify-start md:justify-center items-center rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg h-auto hover:cursor-default transform transition-all hover:scale-110"
            onClick={() => {
              handleAddSubCategoryBtn();
            }}
        >
          <IoIosAddCircleOutline className="md:hidden w-9" />
          <p className="text-center text-md">Add Sub-category</p>
        </div>

        {/* Delete Sub-category Btn */}
        <div
          className=" m-1 my-2 md:my-0 px-2 py-2 w-3/6 md:w-1/6 flex flex-row justify-start md:justify-center items-center rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg h-auto hover:cursor-default transform transition-all hover:scale-110"
          onClick={() => {
            handleSubCategoryDeleteBtn();
          }}
        >
          <MdDeleteOutline className="md:hidden w-9 " />
          <p className="text-center text-md">Delete Sub-category</p>
        </div>
      </div>
      
      {/* results */}
      <div className="flex flex-row w-full p-6 jusstify-center align-middle">
        <div className="mx-1 my-2 p-2 w-1/2 bg-gray-100 rounded-xl">
          {categories &&
            categories.map((category, index) => {
              return (
                <div
                  key={index}
                  className={`mx-1 my-2 px-2 py-4 rounded-xl bg-gray-200 hover:bg-gray-300
                  ${getCategoryClasses(index)}
                  `}
                  onClick={() => {
                    handleCategoryClickBtn(index, category);
                  }}
                >
                  <p className="pl-2">{category.name}</p>
                </div>
              );
            })}
        </div>
        <div className="mx-1 my-2 p-2 w-1/2 h-fit rounded-xl bg-gray-100">
          {subCategories.length > 0 ? (
            subCategories.map((subCategory, index) => {
              return (
                <div
                  key={index}
                  className={`mx-1 my-2 px-2 py-4 rounded-xl bg-gray-200 hover:bg-gray-300
                  ${getSubCategoryClasses(index)}
                  `}
                  onClick={() => {
                    handleSubCategoryClickBtn(index, subCategory);
                  }}
                >
                  <p className="pl-2">{subCategory.name}</p>
                </div>
              );
            })
          ) : (
            <div className="mb-1 px-2 py-4 flex h-full flex-col justify-center items-center rounded-xl bg-gray-200">
              <div>
                <p>No sub-category to view.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCategories;
