import React, { useEffect, useState } from "react";
import "firebase/firestore";
import { Input } from "@material-tailwind/react";

// import { db } from "../../../firebase";
// import { collection, doc, setDoc } from "firebase/firestore";
import axios from "axios";
import { ADD_SUBCATEGORY_API } from "../../Api";
import ErrorMsg from "../AddProductComponents/ErrorMsg";

const AddSubCategory = ({categoryName, onClose}) => {

const [subCatName, setSubCatName] = useState(""); // subcategory name
const [errorPopup, setErrorPopup] = useState(false);
const [errorMsg, setErrorMsg] = useState(false);

const handleNameChange = (e) => {
    setSubCatName(e.target.value);
}

const handleSubmit = async () => {
    // console.log("Submit button clicked");

    //add subcategory to firestore
    // const collectionRef = collection(db, 
    //     "Categories",
    //     categoryName,
    //     "SubCategories"
    //     );
    // const subCategoryData = {
    //     name: subCatName,
    //     id: subCatName,
    // };
    // await setDoc(doc(collectionRef, subCatName), subCategoryData)
    //     .then(() => {
    //         console.log("Sub-Category added successfully");
    //         onClose();
    //     })
    //     .catch((error) => {
    //         console.error("Error adding sub-category: ", error);
    //     });
    if (subCatName === "") {
        console.log("Please fill all the fields");
        setErrorMsg("Please fill all the fields");
        setErrorPopup(true);
        return;
    }
    const formData = new FormData();
    formData.append('subCategoryName', subCatName);
    formData.append('categoryName', categoryName);

    try {
      const response = await axios.post(ADD_SUBCATEGORY_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        console.log("Sub-Category added successfully");
        onClose();
      }
    } catch (error) {
      console.error("Error adding subcategory:", error);
    } 
  

}

const handleCancel = () => {
    onClose();
}
  return (
    <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white md:w-1/3 p-8 rounded-xl shadow-md">
        <h2 className="text-lg font-semibold mb-4">Add Sub-Category</h2>
        {errorPopup && (
          <ErrorMsg
            errMsg={errorMsg}
            onClose={() => setErrorPopup(false)}
          />
        )}
        <Input
          type="text"
          required
          label="Name"
          onChange={handleNameChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />

        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded mr-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddSubCategory;