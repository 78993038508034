import React, { useEffect, useState } from "react";
import "firebase/firestore";
import { Input } from "@material-tailwind/react";
// import { storage, db } from "../../../firebase";
// import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// import { collection, doc, setDoc } from "firebase/firestore";
import axios from "axios";
import { IoIosAdd } from "react-icons/io";
import { MdOutlineDelete } from "react-icons/md";
import ErrorMsg from "./ErrorMsg";
import { UPLOAD_PRODUCT_API } from "../../Api";

const AddProduct = ({ categoryId, subCategoryId, onClose }) => {
  class ProductDetails {
    constructor(id, name, desc, feat, imgs, categoryId, subCategoryId) {
      this.id = id;
      this.name = name;
      this.desc = desc;
      this.feat = feat;
      //   this.manual = manual;
      this.img = imgs;
      this.categoryId = categoryId;
      this.subCategoryId = subCategoryId;
    }
    toString() {
      return this.name + ", " + this.desc;
    }
  }

  // var tempUrl = [];
  // const [imgUrl, setImgUrl] = useState([]); //product image urls
  const [name, setName] = useState(""); //product name
  const [description, setDescription] = useState(""); //product description
  const [feature, setFeature] = useState(""); //product features
  const [image, setImage] = useState([]); //product images
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgPopup, setErrorMsgPopup] = useState(false);

  const [errorPopup, setErrorPopup] = useState(false); //error popup
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleFeatureChange = (e) => {
    setFeature(e.target.value);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    setImage(files);
  };

  const handleImageRemoval = (index) => {
    const tempUploadedImage = [...image];
    tempUploadedImage.splice(index, 1);
    setImage(tempUploadedImage);
  };

  const handleSubmit = async () => {
    if (name == "" || description == "" || feature == "" || image.length == 0) {
      setErrorMsg("Please fill all the fields");
      setErrorPopup(true);
      return;
    }
    const formData = new FormData();
    formData.append("productName", name);
    formData.append("productDescription", description);
    formData.append("productFeatures", feature);
    formData.append("categoryId", categoryId);
    formData.append("subCategoryId", subCategoryId);

    for (let index = 0; index < image.length; index++) {
      formData.append(`productImages`, image[index]);
    }

    // const mediaKeys = Object.keys(image);
    // try {
    //   var i = 0;
    //   // Upload image to Firebase Storage (you need to set up Firebase Storage)
    //   mediaKeys.map((key) => {
    //     const storageRef = ref(
    //       storage,
    //       "Products/" + categoryId + "/" + subCategoryId + "/" + image[key].name
    //     );
    //     const uploadTask = uploadBytesResumable(storageRef, image[key]);
    //     uploadTask.on(
    //       "state_changed",
    //       (snapshot) => {
    //         const progress = Math.round(
    //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //         );
    //         console.log(progress);
    //       },
    //       (err) => {
    //         console.log(err);
    //       },
    //       () => {
    //         getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //           // console.log("Inside download method: " + url);
    //           tempUrl = [...tempUrl, url];
    //           imgUrl.push(url);
    //           // console.log("URL at index " + " " + i + " " + tempUrl[i]);
    //           i++;
    //           // console.log(imgUrl);

    //           //upload product data to firestore
    //           if (image.length == imgUrl.length) {
    //             uploadProductData();
    //           }
    //         });
    //       }
    //     );
    //   });
    // } catch (error) {
    //   console.error("Error adding item:", error);
    // }

    try {
      const response = await axios.post(UPLOAD_PRODUCT_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Product added successfully:", response.data);
    } catch (error) {
      console.error("Error uploading images:", error);
      setErrorMsg("Error uploading images");
      setErrorMsgPopup(true);
    } finally {
      onClose();
    }
    // console.log("Submit button clicked");
  };

  const handleCancel = () => {
    // Close the add item popup without adding an item
    onClose();
  };
  // async function uploadProductData() {
  //   const collectionRef = collection(
  //     db,
  //     "Categories",
  //     categoryId,
  //     "SubCategories",
  //     subCategoryId,
  //     subCategoryId + "_Products"
  //   );
  //   //Split Input
  //   const linesArray = feature.split("\n");
  //   console.log(linesArray);
  //   // Add item to Firestore
  //   const productData = new ProductDetails(
  //     name,
  //     name,
  //     description,
  //     linesArray,
  //     imgUrl,
  //     categoryId,
  //     subCategoryId
  //   );
  //   await setDoc(
  //     doc(collectionRef, name),
  //     JSON.parse(JSON.stringify(productData))
  //   )
  //     .then(() => {
  //       console.log("{Document} successfully written!");

  //       onClose();
  //     })
  //     .catch((error) => {
  //       console.error("Error writing document: ", error);
  //     });
  // }

  return (
    <div className="fixed z-50 overflow-y-auto inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 overflow-y-auto flex flex-col justify-between h-5/6 rounded-xl shadow-md">
        {errorPopup && (
          <ErrorMsg
            errMsg={errorMsg}
            onClose={() => {
              setErrorPopup(false);
            }}
          />
        )}
        <h2 className="text-lg font-semibold mb-4">
          Cool! Let's add a product
        </h2>

        <Input
          required
          type="text"
          label="Name"
          onChange={handleNameChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />
        <div className="relative w-full min-w-[200px]">
          <textarea
            className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
            name="description"
            type="text"
            placeholder=""
            required
            rows={5}
            onChange={handleDescriptionChange}
          ></textarea>
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Description <span className=" text-red-600 pl-0.5"> *</span>
          </label>
        </div>
        <br />
        <div className="relative w-full min-w-[200px]">
          <textarea
            className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
            name="features"
            type="text"
            placeholder=""
            required
            rows={5}
            onChange={handleFeatureChange}
          ></textarea>
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Applications <span className=" text-red-600 pl-0.5"> *</span>
          </label>
        </div>
        <br />
        {/* <Input
          type="text"
          required
          label="Application 2"
          onChange={handleFeature2Change}
          className="border border-gray-300 p-2 w-full"
        /> */}

        <label className="text-xs md:text-md text-gray-600 ml-2">Images <span className=" text-red-600 pl-0.5"> *</span></label>
        <div
          className="image-preview grid grid-cols-4 gap-2
            md:grid-cols-7 lg:grid-cols-9 md:gap-4"
        >
          {image.length > 0 &&
            Object.keys(image).map((index) => {
              return (
                <div className="relative inline-block mb-4" key={index}>
                  <img
                    src={URL.createObjectURL(image[index])}
                    alt="Product"
                    className="w-20 h-20 m-1 p-1 object-cover rounded-xl"
                  />
                  <button
                    onClick={() => {
                      handleImageRemoval(index);
                    }}
                    className=" w-4 h-4 bg-white
                    absolute top-0 right-0 -mr-1 md:mr-0
                    text-white p-2 rounded-full"
                  >
                    <MdOutlineDelete
                      className="text-red-500
                        absolute top-0 right-0 "
                    />
                  </button>
                </div>
              );
            })}
          <label className="text-xs md:text-md text-gray-600">
            <div className="h-full flex justify-start items-center">
              <div className="relative mt-2">
                <input
                  type="file"
                  id="image"
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                  className="sr-only"
                />
                <div
                  htmlFor="image"
                  className="cursor-pointer hover:bg-gray-500
               bg-gray-300 w-14 h-14 m-1 p-1 text-white px-4 
                flex justify-center items-center rounded-full"
                >
                  <IoIosAdd className="w-10 h-10 text-gray-800" />
                </div>
              </div>
            </div>
          </label>

          {/* <img
            src="https://images.unsplash.com/photo-1702573849838-0f66f3731fdf?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className=" m-1 p-1 w-20 h-20 object-cover"
          /> */}
        </div>

        <br />

        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded mr-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
