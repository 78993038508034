import React, { useEffect, useState } from "react";
import "firebase/firestore";
import { Input } from "@material-tailwind/react";
// import { storage, db } from "../../../firebase";
// import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// import { collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import DeleteConfirmation from "../AddClientCompononents/DeleteConfirmation";

import { IoIosAdd } from "react-icons/io";
import { MdOutlineDelete } from "react-icons/md";
import ErrorMsg from "./ErrorMsg";
import { DELETE_PRODUCT_API, EDIT_PRODUCT_API } from "../../Api";
import axios from "axios";

// Merge product details into a single class
// class ProductDetails {
//   constructor(id, name, desc, feat, imgs, categoryId, subCategoryId) {
//     this.id = id;
//     this.name = name;
//     this.desc = desc;
//     this.feat = feat;
//     //   this.manual = manual;
//     this.img = imgs;
//     this.categoryId = categoryId;
//     this.subCategoryId = subCategoryId;
//   }
//   toString() {
//     return this.name + ", " + this.desc + ", " + this.img;
//   }
// }

const EditProduct = ({ prodData, categoryId, subCategoryId, onClose }) => {
  var tempUrl = [];

  const [imgUrl, setImgUrl] = useState([]); //product image urls
  const [name, setName] = useState(""); //product name
  const [description, setDescription] = useState(""); //product description
  const [feature, setFeature] = useState(""); //product features
  const [featureArray, setFeatureArray] = useState([]); //product features in array format
  const [image, setImage] = useState([]); //product image URL's from firebase
  const [uploadedImage, setUploadedImage] = useState([]); //product images
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false); //error popup
  const [errorMsg, setErrorMsg] = useState(""); //error message

  function loadTextFields() {
    const tempFeatureArray = [];
    prodData.feat.map((feature) => {
      tempFeatureArray.push(feature + "\n");
    });

    setName(prodData.name);
    setDescription(prodData.desc);
    setFeatureArray(tempFeatureArray);
    setFeature(tempFeatureArray.join(""));
    setImage(prodData.img);
  }
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleFeatureChange = (e) => {
    setFeature(e.target.value);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    setUploadedImage(files);
  };

  // remove urls from fetched image urls
  const handleRemoveImage = (index) => {
    console.log("Before removing: ");
    console.log(image);
    const tempImage = [...image];
    tempImage.splice(index, 1);
    setImage(tempImage);
    console.log("After removing");
    console.log(image);
  };

  // reomove uploaded images
  const handleLocalRemoveImage = (index) => {
    // console.log("Before removing: ");
    // console.log(image);
    const tempUploadedImage = [...uploadedImage];
    tempUploadedImage.splice(index, 1);
    setUploadedImage(tempUploadedImage);
    // console.log("After removing");
    // console.log(image);
  };

  const handleSubmit = async () => {
    setImgUrl([]);
    for (let i = 0; i < image.length; i++) {
      imgUrl.push(image[i]);
    }
    const formData = new FormData();
    formData.append("productName", name);
    formData.append("productDescription", description);
    formData.append("productFeatures", feature);
    formData.append("categoryId", categoryId);
    formData.append("subCategoryId", subCategoryId);

    for (let index = 0; index < imgUrl.length; index++) {
      formData.append("productExistingImgUrl", imgUrl[index]);
    }

    for (let index = 0; index < uploadedImage.length; index++) {
      formData.append(`productNewImages`, uploadedImage[index]);
    }
    try {
      const response = await axios.post(EDIT_PRODUCT_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Product added successfully:", response.data);
    } catch (error) {
      console.error("Error uploading images:", error);
      setErrorMsg("Error uploading images");
      setErrorPopup(true);
    } finally {
      onClose();
    }
    // const mediaKeys = Object.keys(uploadedImage);
    // try {
    //   var i = 0;
    //   // Upload image to Firebase Storage (you need to set up Firebase Storage)
    //   if (uploadedImage.length > 0) {
    //     mediaKeys.map((key) => {
    //       const storageRef = ref(
    //         storage,
    //         "Products/" +
    //           categoryId +
    //           "/" +
    //           subCategoryId +
    //           "/" +
    //           uploadedImage[key].name
    //       );
    //       const uploadTask = uploadBytesResumable(
    //         storageRef,
    //         uploadedImage[key]
    //       );
    //       uploadTask.on(
    //         "state_changed",
    //         (snapshot) => {
    //           const progress = Math.round(
    //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //           );
    //           console.log(progress);
    //         },
    //         (err) => {
    //           console.log(err);
    //         },
    //         () => {
    //           getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //             // console.log("Inside download method: " + url);
    //             tempUrl = [...tempUrl, url];
    //             imgUrl.push(url);
    //             // console.log("URL at index " + " " + i + " " + tempUrl[i]);
    //             i++;
    //             // console.log(imgUrl);

    //             //upload product data to firestore
    //             // console.log("Uploaded image length: " + uploadedImage.length);
    //             // console.log("Image length: " + image.length);
    //             // console.log("imgUrl length: " + imgUrl.length);

    //             if (uploadedImage.length + image.length == imgUrl.length) {
    //               uploadProductData();
    //             }
    //           });
    //         }
    //       );
    //     });
    //   } else if (uploadedImage.length == 0) {
    //     uploadProductData();
    //   }
    //   // Close the add item popup
    // } catch (error) {
    //   console.error("Error adding item:", error);
    // }

    // console.log("Submit button clicked");
  };
  // async function uploadProductData() {
  //   // console.log("Inside uploadProductData method");
  //   // console.log("LOOOOOOOOOK: " + imgUrl);
  //   // console.log(feature);

  //   const linesArray = feature.split("\n");
  //   if (linesArray[linesArray.length - 1] == "") {
  //     linesArray.pop();
  //   }
  //   const collectionRef = collection(
  //     db,
  //     "Categories",
  //     categoryId,
  //     "SubCategories",
  //     subCategoryId,
  //     subCategoryId + "_Products"
  //   );
  //   // Add product to Firestore
  //   const productData = new ProductDetails(
  //     name,
  //     name,
  //     description,
  //     linesArray,
  //     imgUrl,
  //     categoryId,
  //     subCategoryId
  //   );
  //   await setDoc(
  //     doc(collectionRef, name),
  //     JSON.parse(JSON.stringify(productData))
  //   )
  //     .then(() => {
  //       console.log("{Document} successfully written!");

  //       onClose();
  //     })
  //     .catch((error) => {
  //       console.error("Error writing document: ", error);
  //     });
  // }
  //   Delete product
  const handleDeleteBtn = () => {
    // console.log("Delete button clicked");
    setDeleteConfirmation(!deleteConfirmation);
  };
  const deleteProduct = async () => {
    // const collectionRef = collection(
    //   db,
    //   "Categories",
    //   categoryId,
    //   "SubCategories",
    //   subCategoryId,
    //   subCategoryId + "_Products"
    // );
    // const docRef = doc(collectionRef, prodData.name);
    // try {
    //   deleteDoc(docRef)
    //     .then(() => {
    //       console.log("Document successfully deleted!");
    //       onClose();
    //     })
    //     .catch((error) => {
    //       console.error("Error removing document: ", error);
    //     });
    // } catch (error) {
    //   console.error("Error adding item:", error);
    // }
    try {
      const response = await axios.delete(
        DELETE_PRODUCT_API +
          categoryId +
          "/" +
          subCategoryId +
          "/" +
          prodData.id // CHANGED from Name to Id
      );
      console.log("Product deleted successfully:", response.data);
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      onClose();
    }
  };
  const handleCancel = () => {
    onClose();
  };
  useEffect(() => {
    loadTextFields();
  }, []);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClose]);
  return (
    <div className="fixed z-50 inset-0 overflow-auto overflow-y-auto bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 overflow-y-auto flex flex-col justify-between h-5/6 rounded-xl shadow-md">
        {errorPopup && (
          <ErrorMsg
            errMsg={errorMsg}
            onClose={() => {
              setErrorPopup(false);
            }}
          />
        )}
        {
          // Delete confirmation popup
          deleteConfirmation && (
            <DeleteConfirmation
              onConfirm={deleteProduct}
              onCancel={() => setDeleteConfirmation(false)}
            />
          )
        }
        <h2 className="text-md md:text-lg font-semibold mb-4">
          Okay! Let's edit existing product
        </h2>

        <Input
          type="text"
          label="Name"
          value={name}
          required
          onChange={handleNameChange}
          className="border
          text-sm
          md:text-md border-gray-300 p-2 w-full"
        />
        <br />
        <div className="relative w-full min-w-[200px]">
          <textarea
            className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
            name="description"
            type="text"
            placeholder=""
            required
            value={description}
            rows={5}
            onChange={handleDescriptionChange}
          ></textarea>
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Description <span className=" text-red-600 pl-0.5"> *</span>
          </label>
        </div>
        <br />
        {/* <label className="text-xs md:text-md text-gray-600 ml-2">
          Applications
          <textarea
            type="text"
            rows={4}
            label="Applications"
            value={feature}
            onChange={handleFeatureChange}
            className="rounded-lg border border-gray-400 
          text-sm
          md:text-md text-gray-800 p-2 mb-4 w-full"
          />
        </label> */}
        <div className="relative w-full min-w-[200px]">
          <textarea
            className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
            name="features"
            type="text"
            placeholder=""
            required
            rows={5}
            value={feature}
            onChange={handleFeatureChange}
          ></textarea>
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Applications <span className=" text-red-600 pl-0.5"> *</span>
          </label>
        </div>
        <br />
        <label
          className="text-xs md:text-md
         text-gray-600 ml-2"
        >
          Images <span className=" text-red-600 pl-0.5"> *</span>
        </label>
        <div
          className="image-preview grid grid-cols-4 gap-2 
          md:grid-cols-7 lg:grid-cols-9 md:gap-4"
        >
          {image.map((img, index) => {
            return (
              <div className="relative inline-block mb-4" key={index}>
                <img
                  src={img}
                  alt="Product"
                  className="w-20 h-20 m-1 p-1 object-cover rounded-xl"
                />
                <button
                  onClick={() => {
                    handleRemoveImage(index);
                  }}
                  className=" w-4 h-4 bg-white
                  absolute top-0 right-0 -mr-1 md:mr-0
                   text-white p-2 rounded-full"
                >
                  <MdOutlineDelete className="text-red-500  absolute top-0 right-0 " />
                </button>
              </div>
            );
          })}
          {uploadedImage.length > 0 &&
            Object.keys(uploadedImage).map((key) => {
              return (
                <div className="relative inline-block mb-4" key={key}>
                  <img
                    src={URL.createObjectURL(uploadedImage[key])}
                    alt="Product"
                    className="w-20 h-20 m-1 p-1 object-cover rounded-xl"
                  />
                  <button
                    onClick={() => {
                      handleLocalRemoveImage(key);
                    }}
                    className=" w-4 h-4 bg-white absolute
                      top-0 right-0 -mr-1 md:mr-0
                    text-white p-2 rounded-full"
                  >
                    <MdOutlineDelete
                      className="text-red-500
                        absolute top-0 right-0 "
                    />
                  </button>
                </div>
              );
            })}
          <label className="text-xs md:text-md text-gray-600">
            <div className="h-full flex justify-start items-center">
              <div className="relative mt-2">
                <input
                  type="file"
                  id="image"
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                  className="sr-only"
                />
                <div
                  htmlFor="image"
                  className="cursor-pointer hover:bg-gray-500
                  bg-gray-300 w-14 h-14 m-1 p-1 text-white px-4 
                  flex justify-center items-center rounded-full"
                >
                  <IoIosAdd className="w-10 h-10 text-gray-800" />
                </div>
              </div>
            </div>
          </label>

          {/* <img
            src="https://images.unsplash.com/photo-1702573849838-0f66f3731fdf?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className=" m-1 p-1 w-20 h-20 object-cover"
          /> */}
        </div>

        <br />
        <br />

        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded mr-4"
            onClick={handleSubmit}
          >
            Update
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded mr-4"
            onClick={handleDeleteBtn}
          >
            Delete
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
