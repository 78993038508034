// Navbar.js
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMdMenu, IoMdClose } from "react-icons/io";
import logo from "../../assets/sun_tek_logo.png";
import { FETCH_CATEGORIES_API } from "../Api";

//firebase imports
// import { getDocs, collection} from "firebase/firestore";
// import { db } from "../../firebase";

const NewNav = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  // const [errInFetchingCategories, setErrInFetchingCategories] = useState(false);
  // const tempCategoryData = [];

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  async function fetchCategoriesFromServer() {
    // const categoriesCollection = collection(db, 'Categories') // Reference to the 'categories' collection in Firestore

    // try {
    //   const querySnapshot = await getDocs(categoriesCollection); // Fetch all documents in the 'products' collection

    //   querySnapshot.forEach((item) => {

    //     const categoryData = item.data();

    //     tempCategoryData.push(categoryData);
    //   });
    //   setCategories(tempCategoryData);
    //   // Set the categories data in state

    // } catch (error) {
    //   console.error('Error fetching category list', error);
    //   setErrInFetchingCategories(true);
    // }

    try {
      const response = await fetch(FETCH_CATEGORIES_API);
      const categoriesData = await response.json();
      // console.log(categoriesData);
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }

  const dummyDataCategories = [
    { name: "Air Quality", url: "category1" },
    { name: "Water Quality", url: "category2" },
    { name: "Industrial health and hygiene", url: "category2" },
    { name: "IOT solutions", url: "category2" },
  ];

  useEffect(() => {
    fetchCategoriesFromServer(); // Fetch categories when the component mounts
  }, []);

  return (
    <nav
      className={`bg-white p-4 h-16 md:h-28 ${showMobileMenu ? "h-auto" : ""}`}
    >
      <div className=" flex justify-normal items-center">
        <div className="w-11/12">
          <img src={logo} alt="cmpy_logo" className="object-cover w-3/6" />
        </div>

        {/* Mobile icons */}
        <div className="md:hidden">
          {showMobileMenu ? (
            <IoMdClose
              className="text-black text-2xl cursor-pointer transition-transform transform hover:scale-110"
              onClick={toggleMobileMenu}
            />
          ) : (
            <IoMdMenu
              className="text-black text-2xl cursor-pointer transition-transform transform hover:scale-110"
              onClick={toggleMobileMenu}
            />
          )}
        </div>

        {/* Desktop */}
        <div className=" w-screen relative z-50 hidden md:block">
          <ul className="flex flex-row justify-evenly align-middle">
          <li>
              {/* <Link
                to="/signin"
                className={`text-white hover:text-green-500 ${
                  location.pathname === "/contact" ? "text-green-700" : ""
                }`}
              >
                Admin
              </Link> */}
            </li>
            <li>
              <Link
                to="/"
                className={`text-black hover:text-green-500 ${
                  location.pathname === "/" ? "text-green-700" : ""
                }`}
              >
                Home
              </Link>
            </li>

            <li className="z-50 relative group">
              <div
                className={`text-black hover:text-green-500 ${
                  location.pathname.includes("/products")
                    ? "text-green-700"
                    : ""
                }`}
              >
                Products
              </div>
              {/* that 45 deg rotated div */}

              <div className="flex-col hidden group-hover:block absolute  p-2 space-y-2">
                <div className=" -z-0 py-3 absolute -top-2 left-1">
                  <div
                    className="w-4 h-4 left-3 absolute 
                    mt-1 bg-gray-100 rotate-45"
                  ></div>
                </div>
                <div className=" p-1 w-64 h-auto bg-gray-100 rounded-md">
                  {
                  categories.map((category, index) => (
                    <div
                      key={index}
                      className="p-2 rounded-md hover:bg-gray-300"
                    >
                      <Link
                        to={`/products/${category.id}`}
                        className={`text-black hover:text-green-500 ${
                          location.pathname === `/products/${category.id}`
                            ? "text-green-700"
                            : ""
                        }`}
                      >
                        {category.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <Link
                to="/contact"
                className={`text-black hover:text-green-500 ${
                  location.pathname === "/contact" ? "text-green-700" : ""
                }`}
              >
                Contact Us
              </Link>
            </li>
            
          </ul>
        </div>
        {/* Mobile Menu */}
      </div>
      <ul
        className={`flex flex-col mt-2 ${
          showMobileMenu
            ? "opacity-100 transform translate-y-0 transition-all duration-300 ease-in-out"
            : "opacity-0 transform -translate-y-2 transition-all duration-300 ease-in-out"
        }`}
      >
        <li className="py-3">
          <Link
            to="/"
            className={`text-black hover:text-green-500 ${
              location.pathname === "/" ? "text-green-700" : ""
            }`}
          >
            Home
          </Link>
        </li>
        <li
          className="py-3"
          onClick={() => {
            setShowCategories(!showCategories);
          }}
        >
          <div 
          className={`text-black hover:text-green-500 ${
            location.pathname.includes("/products")
              ? "text-green-700"
              : ""
          }`}
          >Products</div>
          <ul
            className={`flex flex-col mt-2 ${
              showCategories
                ? "opacity-100 transform translate-y-0 transition-all duration-300 ease-in-out"
                : "opacity-0 transform -translate-y-2 transition-all duration-300 ease-in-out"
            }`}
          >
            {showCategories &&
              categories.map((category, index) => (
                <div key={index}>
                  <li className="py-3 pl-6 text-black">
                    <Link
                      to={`/products/${category.id}`}
                      className={`text-black hover:text-green-500 ${
                        location.pathname === `/products/${category.id}`
                          ? "text-green-700"
                          : ""
                      }`}
                    >
                      {category.name}
                    </Link>
                  </li>
                </div>
              ))}
          </ul>
        </li>
        <li className="py-3">
          <Link
            to="/contact"
            className={`text-black hover:text-green-500 ${
              location.pathname === "/contact" ? "text-green-700" : ""
            }`}
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NewNav;
