import React from "react";

const AboutCard = ({imgSrc,title,content}) => {
  return (
    <div className="relative w-70 h-72 rounded-2xl">
      <img
        src={imgSrc}
        alt={title}
        className="object-cover w-70 h-72 rounded-2xl"
      />
      <div className="absolute inset-0 flex flex-col justify-center items-center bg-opacity-50 bg-gray-900 rounded-2xl">
        <h1 className="container text-center text-lg font-bold leading-10 mb-3 text-white dark:text-gray-100 sm:text-md sm:leading-5 md:text-xl md:leading-relaxed ">
          {title}
        </h1>
        <p className="m-2 p-2 text-justify font-kumbh font-normal leading-relaxed text-white opacity-85">
         {content}
        </p>
      </div>
    </div>
  );
};

export default AboutCard;
