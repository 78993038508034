import React from "react";
// import { signOut, getAuth } from "firebase/auth";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { TbLogout2 } from "react-icons/tb";

const SignOutBtn = () => {
  const navigate = useNavigate();
  // const auth = getAuth();

  async function handleSignOut() {
    try {

      localStorage.removeItem("user");
      localStorage.removeItem("authToken"); // Remove the token from localStorage
      localStorage.setItem("isLoggedIn", false);
      navigate("/signin");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="Navbar ">
      <div className="m-3 md:m-5 p-3 md:p-6 flex flex-row justify-end align-middle">
        <Button
          className="hidden md:block"
          onClick={() => {
            handleSignOut();
          }}
        >
          Sign out
        </Button>
        <TbLogout2
          className="md:hidden w-6 h-6"
          onClick={() => {
            handleSignOut();
          }}
        />
      </div>
    </div>
  );
};

export default SignOutBtn;
