import React, { useEffect, useState } from "react";
import { Input, Typography } from "@material-tailwind/react";

//firebase imports
// import { db } from "../../../firebase";
// import { collection, getDocs, query, doc, deleteDoc } from "firebase/firestore";
import classNames from "classnames";
import DeleteConfirmation from "./DeleteConfirmation";
import AddClient from "./AddClient";
import SignOutBtn from "../SignOutBtn/SignOutBtn";

import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { DELETE_CLIENT_API, FETCH_CLIENTS_API } from "../../Api";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AddClients = () => {
  // const collectionRef = collection(db, "Clients");

  var isFetched = false;
  // var tempClientsDataArr = [];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // check if user is authenticated
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, []);

  const [addClient, setAddClient] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedClient, setSelectedClient] = useState(false);
  const [selectedClientIndex, setSelectedClientIndex] = useState(false);
  const [clientsData, setclientsData] = useState([]);
  const [search, setSearch] = useState("");
  // var querySnapshot;

  function display() {
    // console.log("Inside display method");

    if (isFetched == true) {
      // console.log(clientsData);
    }
  }
  const handleDeleteBtn = () => {
    // console.log("Delete button clicked");
    if (selectedClientId === null) {
      return;
    }
    setSelectedClient(!selectedClient);
  };
  const handleAddClientBtn = () => {
    setAddClient(!addClient);
  };

  const handleImgClickBtn = (id) => {
    console.log("Clicked img ", id);
    setSearch(id);
    setSelectedClientIndex(!selectedClientIndex);
    setSelectedClientId(id);
    if (selectedClientIndex) {
      setSearch("");
    }

    if (id === selectedClientId) {
      setSelectedClientId(null);
    }
    scrollToTop();
  };
  //classNames to conditionally apply Tailwind classes
  const getItemClasses = () =>
    classNames("cursor-pointer", {
      "bg-red-400": selectedClientIndex,
      "bg-gray-100": !selectedClientIndex,
      "font-bold": selectedClientIndex,
      "font-normal": !selectedClientIndex,
      "transform transition-all scale-110": selectedClientIndex,
    });

  // async function fetchData() {
  //   const que = query(collectionRef);
  //   querySnapshot = await getDocs(que);

  //   querySnapshot.forEach((doc) => {
  //     const clientsData = {
  //       name: "",
  //       imgSrc: "",
  //     };

  //     clientsData.name = doc.get("name");
  //     clientsData.imgSrc = doc.get("imgSrc");

  //     tempClientsDataArr.push(clientsData);
  //     // console.log(doc.name, " => ", doc.get("name"));
  //     var existingId = doc.get("name");
  //     console.log(existingId);
  //   });
  //   isFetched = true;
  //   setclientsData(tempClientsDataArr);
  // }
  async function fetchClients() {
    try {
      const response = await fetch(FETCH_CLIENTS_API);
      const clientsData = await response.json();
      setclientsData(clientsData);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  }
  async function deleteClient() {
    // try {
    //   await deleteDoc(doc(db, "Clients", selectedClientId))
    //     .then(() => {
    //       console.log("Document successfully deleted!");
    //     })
    //     .catch((error) => {
    //       console.error("Error removing document: ", error);
    //     });
    // } catch (error) {
    //   console.log("Deletion error ", error);
    // }
    try {
      const response = await fetch(DELETE_CLIENT_API + selectedClientId, {
        method: "DELETE",
      });
    } catch (error) {
      console.log("Deletion error ", error);
    }

    setSelectedClientId(null);
    fetchClients();
  }
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setSelectedClientIndex(false);
        setSearch("");
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedClientIndex]);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    display();
  }, [clientsData]);

  return (
    <div>
      <SignOutBtn />
      {addClient && (
        <AddClient
          onClose={() => {
            setAddClient(!addClient);
            fetchClients();
          }}
        />
      )}
      {selectedClient && selectedClientId && (
        <DeleteConfirmation
          onConfirm={() => {
            deleteClient();
            setSelectedClientIndex(false);
            setSelectedClient(false);
            setSearch("");
          }}
          onCancel={() => {
            setSelectedClientIndex(false);
            setSelectedClientId(null);
            setSelectedClient(false);
            setSearch("");
          }}
        />
      )}

      <div>
        <h2 className="mt-20 mb-20 text-center text-2xl md:text-3xl font-extrabold text-gray-900">
          Add or Edit Clients
        </h2>
      </div>
      <div className=" Buttons lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-row justify-around align-middle">
        <div
          className=" m-1 px-4 w-1/6 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg  h-auto hover:cursor-default transform transition-all hover:scale-110"
          onClick={() => {
            handleAddClientBtn();
          }}
        >
          <p className="hidden md:block text-center text-md">Add Client</p>
          <IoIosAddCircleOutline className="md:hidden w-9" />
        </div>

        <div
          onClick={() => {
            handleDeleteBtn();
          }}
          className={` m-1 px-4 rounded-2xl flex flex-col justify-center items-middle bg-gray-100 ${getItemClasses()} hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto hover:cursor-default transform transition-all hover:scale-110`}
          //className=" m-1 px-4 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto hover:cursor-default transform transition-all hover:scale-110"
          //   onClick={() => {
          //     handleClick(index);
          //   }}
        >
          <p className="hidden md:block text-center text-md">Delete Client</p>
          <MdDeleteOutline className="md:hidden w-9 " />
        </div>

        <div className=" m-1 md:px-4 rounded-2xl hover:drop-shadow-lg w-min md:w-3/6 h-11 hover:cursor-default">
          <Input
            label="Search"
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Typography
            variant="small"
            color="gray"
            className="mt-2 flex items-center gap-1 font-normal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="-mt-px h-4 w-4"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
            Search for client
          </Typography>
        </div>
      </div>
      <div className="md:hidden">
        <br />
      </div>
      <div className="m-2 p-2 lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-row justify-center align-middle">
        <table className=" w-full table-auto border border-slate-500 border-separate border-spacing-2">
          <thead>
            <tr>
              <th className="p-2 border border-slate-600">S.No</th>
              <th className="p-2 border border-slate-600">Name</th>
              <th className="p-2 border border-slate-600">Image</th>
            </tr>
          </thead>
          <tbody>
            {clientsData
              .filter((item) => {
                if (search == "") {
                  return item;
                } else if (
                  item.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return item;
                }
              })
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="py-2 px-4 border border-slate-600">
                      {index + 1}
                    </td>
                    <td className="py-2 px-4 border border-slate-600">
                      {item.name}
                    </td>
                    <td className="p-4 border border-slate-600">
                      <img
                        src={item.imgSrc}
                        alt="prod image"
                        className="object-contain w-48 h-48"
                        onClick={() => {
                          handleImgClickBtn(item.name);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddClients;
