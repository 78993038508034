import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const CountDown = () => {
  const [isVisible, setIsVisible] = useState(false);
  const customers = 2000;
  const products = 200;
  const yrs = 25;

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
        console.log("Inside use effcet");
      }
    };

    window.addEventListener("scrollx", toggleVisibility);

    return () => window.removeEventListener("scrollx", toggleVisibility);
  }, []);

  return (
    <ScrollTrigger
      onEnter={() => setIsVisible(true)}
      onExit={() => setIsVisible(false)}
    >
      <div className="  lg:py-16 md:py-12 h-full flex flex-col justify-evenly">
        <div className="mb-4 p-2">
          <h1 className="text-center lg:pl-0 lg:pr-0 text-2xl font-extrabold leading-10 
          tracking-tight mb-3 text-gray-90 sm:text-4xl sm:leading-10 md:text-4xl md:leading-normal">
            Our
            <span className="text-green-500 font-medium"> Track Record</span>
          </h1>
        </div>
        <div className="w-screen p-2 flex flex-row justify-center align-middle bg-green-500">
          <div className="flex flex-col justify-center items-center m-1 w-64 h-64 ">
            <h2 className="text-center text-2xl font-extrabold leading-10 tracking-tight mb-3 text-white sm:text-4xl sm:leading-10">
              {isVisible && (
                <CountUp start={0} end={customers} duration={2} delay={0} />
              )}{" "}
              +
            </h2>
            <h2 className="text-center text-xl font-extrabold leading-10 tracking-tight mb-3  text-white  sm:text-3xl sm:leading-10">
              Customers
            </h2>
          </div>

          <div className="flex flex-col justify-center items-center m-1 w-64 h-64 ">
            <h2 className=" text-2xl font-extrabold leading-10 tracking-tight mb-3  text-white  sm:text-4xl sm:leading-10">
              {isVisible && (
                <CountUp start={0} end={products} duration={2} delay={0} />
              )}{" "}
              +
            </h2>
            <h2 className="text-center text-xl font-extrabold leading-10 tracking-tight mb-3  text-white  sm:text-3xl sm:leading-10">
              Products
            </h2>
          </div>
          <div className="flex flex-col justify-center items-center m-1 w-64 h-64 ">
            <h2 className=" text-2xl font-extrabold leading-10 tracking-tight mb-3  text-white sm:text-4xl sm:leading-10">
              {isVisible && (
                <CountUp start={0} end={yrs} duration={2} delay={0} />
              )}{" "}
              +
            </h2>
            <h2 className="text-center text-xl font-extrabold leading-10 tracking-tight mb-3  text-white  sm:text-3xl sm:leading-10">
              Years
            </h2>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default CountDown;
