import React from "react";

const HeroItem = ({ title, bold_title, desc1, desc2, desc3, desc4, desc5, desc6, needTransparency, imgSrc }) => {
  return (
    <div className="relative w-full h-full">
      <img src={imgSrc} alt="" className="md:object-fill object-cover w-screen h-screen" />
      <div className={`pl-6 md:pl-20 absolute inset-0 flex flex-col items-center justify-center bg-opacity-75 bg-gradient-to-r ${needTransparency ? "from-transparent to-transparent" : "from-black to-transparent"} `}>
        <h1 className="container pl-2 pr-14 lg:pl-0 lg:pr-0 text-3xl font-extrabold leading-10 tracking-tight mb-3 text-white dark:text-gray-100 sm:text-4xl sm:leading-10 items-start text-left md:text-5xl md:leading-normal ">
         {title} <p className="font-medium text-green-500">{bold_title}</p>
        </h1>
        <div className="container flex flex-col justify-start items-start pl-2 md:pl-0">
          <p className="leading-relaxed text-white opacity-75  text-lg md:text-2xl">
            {desc1} <br />
            {desc2} <br />
            {desc3} <br />
            {desc4} <br />
            {desc5} <br />
            {desc6}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroItem;
