import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "./InputField";
import sun_tek_logo from "../../assets/sun_tek_logo.png";
import FormAction from "./FormAction";
import { loginFields } from "./LoginFields";
import { LOGIN_API, RAISE_ALERT_API } from "../Api";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const SignIn = () => {
  const navigate = useNavigate();
  const fields = loginFields;
  let fieldsState = {};
  
  fields.forEach((field) => (fieldsState[field.id] = ""));
  const [loginState, setLoginState] = useState(fieldsState);

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const authenticateUser = async (event) => {
    event.preventDefault();
    const { email, password, name, reason } = event.target;
    const emailValue = email.value;
    const passwordValue = password.value;
    const nameValue = name.value;
    const reasonValue = reason.value;
    console.log(emailValue, passwordValue, nameValue, reasonValue);

    const alertFormData = new FormData();
    alertFormData.append("userName", nameValue);
    alertFormData.append("reason", reasonValue);
    //Send Login alert to admin
   try{
      const response = await axios.post(
        RAISE_ALERT_API,
        alertFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 202) {
        console.log("Alert sent successfully");
      }
    }
    catch(error){
      console.log("Error: ", error);
    }
 
    //Sign-in user
    try {
      const response = await axios.post(
        LOGIN_API,
       
        {
          emailValue,
          passwordValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
        
      );

      if (response.status === 200) {
        const token = response.data.token;
        // // Log token expiry time
        // const decodedToken = jwtDecode(token);
        // const expiryTime = new Date(decodedToken.exp * 1000);
        // console.log("Token expiry time:", expiryTime.toLocaleString());
        
        // // Save token to local storage
        localStorage.setItem("authToken", token);
        localStorage.setItem("isLoggedIn", true);
        console.log("Login successful");
        alert("Login successful");

        // Use the navigate function to redirect the user
        navigate("/admin");
      } else {
        console.error("Login failed");
        alert(response.status)
      }
    } catch (error) {
      console.error("Login Failed", error);
      alert(error);
     
    }
  };
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
    } else {
      console.log("AuthContext - user:", true);
      navigate("/admin");
    }
  } , []);

  return (
    <div className="w-full">
      <div className="mt-4 mb-10">
        <div className="flex p-4 justify-center">
          <img alt="" className="object-cover w-1/3" src={sun_tek_logo} />
        </div>
        <h2 className="mt-20 text-center text-3xl font-extrabold text-gray-900">
          Admin Login
        </h2>
      </div>
      <form onSubmit={authenticateUser}>
        <div className="flex flex-row justify-center">
          <div className="flex flex-col items-start">
            {fields.map((field) => (
              <InputField
                key={field.id}
                handleChange={handleChange}
                value={loginState[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                label={field.label}
              />
            ))}
            <FormAction text="Login" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
