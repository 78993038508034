import React, { useEffect, useState } from "react";
import SignOutBtn from "../SignOutBtn/SignOutBtn";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { Input, Typography } from "@material-tailwind/react";
import classNames from "classnames";
import AddManual from "./AddManual";
import {
  DELETE_MANUAL_API,
  FETCH_ALL_MANUAL_API,
  FETCH_MANUAL_API,
} from "../../Api";
import axios from "axios";
import DeleteConfirmation from "../AddClientCompononents/DeleteConfirmation";
import ErrorMsg from "../AddProductComponents/ErrorMsg";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AddManuals = () => {
  const [search, setSearch] = useState(""); //Search term
  const [manualData, setManualData] = useState(null); //manual data
  const [selectedManual, setSelectedManual] = useState(false); //selected manual
  const [manualName, setManualName] = useState(""); //manual name
  const [selectedManualId, setSelectedManualId] = useState(null); //selected manual id
  const [selectedManualIndex, setSelectedManualIndex] = useState(false); //selected manual index
  const [isDeleteClicked, setIsDeleteClicked] = useState(false); //delete button clicked

  const [errorMsgPopup, setErrorMsgPopup] = useState(false); //error message popup
  const [errorMsg, setErrorMsg] = useState(""); //error message
  const [addManual, setAddManual] = useState(false); //add manual popup

  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, []);

  // decorative function for delete button
  const getItemClassesDelete = () =>
    classNames("cursor-pointer", {
      "bg-red-400": selectedManualIndex,
      "bg-gray-100": !selectedManualIndex,
      "font-bold": selectedManualIndex,
      "font-normal": !selectedManualIndex,
      // "transform transition-all scale-110": selectedManualIndex,
    });

    // decorative function for download button
  const getItemClassesDownload = () =>
    classNames("cursor-pointer", {
      "bg-yellow-200": selectedManualIndex,
      "bg-gray-100": !selectedManualIndex,
      "font-bold": selectedManualIndex,
      "font-normal": !selectedManualIndex,
      // "transform transition-all scale-110": selectedManualIndex,
    });

  const handleAddManualBtn = () => {
    setAddManual(!addManual);
  };
  const handleNameClickBtn = (manual) => {
    console.log("Clicked : ", manual.name);
    setSearch(manual.name);
    setManualName(manual.name);
    setSelectedManualIndex(!selectedManualIndex);
    setSelectedManualId(manual.id);
    if (selectedManualIndex) {
      setSearch("");
    }
    // if same manual is clicked again, unselect it
    if (manual.id === selectedManualId) {
      setSelectedManualId(null);
      setSelectedManualIndex(false);
      setSelectedManual(false);
      setManualName("");
    }
  };
  const handleDownloadBtn = () => {
    console.log("Download button clicked");   
    downloadManual();
  };

  const deleteManual = async () => {
    if (selectedManualId === null) {
      return;
    }
    try {
      const response = await axios.delete(DELETE_MANUAL_API + selectedManualId);
      if (response.status === 200) {
        console.log("Manual deleted successfully");
        fetchManuals();
      } else if ((response.status = 202)) {
        console.log("Manual does not exist");
        setErrorMsg("Manual does not exist");
        setErrorMsgPopup(true);
      }
      setSelectedManual(!selectedManual);
    } catch (error) {
      console.error("Error deleting file:", error);
      setErrorMsg("Error deleting file");
      setErrorMsgPopup(true);
    }
  };

  const downloadManual = async () => {
    if (selectedManualId === null) {
      return;
    }
    try {
      const response = await axios.get(FETCH_MANUAL_API + selectedManualId, {
        responseType: "blob",
      });
      if (response.status === 200) {
        console.log("Manual downloaded successfully");
        const fileDataFromServer = response.data;
        const blob = new Blob([fileDataFromServer], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = manualName + ".pdf";
        link.click();
      } else if ((response.status = 202)) {
        console.log("Manual does not exist");
        setErrorMsg("Manual does not exist");
        setErrorMsgPopup(true);
      }
      setSelectedManual(!selectedManual);
    } catch (error) {
      console.error("Error downloading file:", error);
      setErrorMsg("Error downloading file");
      setErrorMsgPopup(true);
    }
  };

  const fetchManuals = async () => {
    const response = await fetch(FETCH_ALL_MANUAL_API);
    const data = await response.json();
    if (response.status === 404) {
      setErrorMsg(response.statusText);
      setErrorMsgPopup(true);
      return;
    }
    setManualData(data.fileData);
  };

  const handleDeleteBtn = () => {
    if (selectedManualId === null) {
      return;
    }
    setSelectedManual(!selectedManual);
    setIsDeleteClicked(!isDeleteClicked);
  };

  useEffect(() => {
    fetchManuals();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setSelectedManualIndex(false);
        setSearch("");
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedManualIndex]);

  return (
    <div>
      <SignOutBtn />
      {errorMsgPopup && (
        <ErrorMsg
          message={errorMsg}
          onClose={() => {
            setErrorMsgPopup(false);
          }}
        />
      )}
      {addManual && (
        <AddManual
          onClose={() => {
            setAddManual(!addManual);
            fetchManuals();
          }}
        />
      )}
      {selectedManual && selectedManualId && isDeleteClicked && (
        <DeleteConfirmation
          onConfirm={() => {
            deleteManual();
            setSelectedManualIndex(false);
            setSelectedManual(false);
            setSearch("");
          }}
          onCancel={() => {
            setSelectedManualIndex(false);
            setSelectedManualId(null);
            setSelectedManual(false);
            setSearch("");
          }}
        />
      )}

      <div>
        <h2 className="mt-20 mb-20 text-center text-2xl md:text-3xl font-extrabold text-gray-900">
          Add or Download Product Manual
        </h2>
      </div>
      <div className=" Buttons lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-row justify-around align-middle">
        <div
          className=" m-1 px-2 w-1/6 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg  h-auto hover:cursor-default transform transition-all hover:scale-110"
          onClick={() => {
            handleAddManualBtn();
          }}
        >
          <p className="hidden md:block text-center text-md">Add Manual</p>
          <IoIosAddCircleOutline className="md:hidden w-9" />
        </div>
        <div
          onClick={() => {
            handleDownloadBtn();
          }}
          className={` m-1 px-2 rounded-2xl flex flex-col 
          justify-center items-middle bg-gray-100 ${getItemClassesDownload()}
        hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto
          hover:cursor-default transform transition-all hover:scale-110`}
          //className=" m-1 px-4 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto hover:cursor-default transform transition-all hover:scale-110"
          //   onClick={() => {
          //     handleClick(index);
          //   }}
        >
          <p className="hidden md:block text-center text-md">Download Manual</p>
          <IoCloudDownloadOutline className="md:hidden w-9 " />
        </div>
        <div
          onClick={() => {
            handleDeleteBtn();
          }}
          className={` m-1 px-2 rounded-2xl flex flex-col 
          justify-center items-middle bg-gray-100 ${getItemClassesDelete()}
        hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto
          hover:cursor-default transform transition-all hover:scale-110`}
          //className=" m-1 px-4 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto hover:cursor-default transform transition-all hover:scale-110"
          //   onClick={() => {
          //     handleClick(index);
          //   }}
        >
          <p className="hidden md:block text-center text-md">Delete Manual</p>
          <MdDeleteOutline className="md:hidden w-9 " />
        </div>

        <div className=" m-1 md:px-4 rounded-2xl hover:drop-shadow-lg w-min md:w-3/6 h-11 hover:cursor-default">
          <Input
            label="Search"
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Typography
            variant="small"
            color="gray"
            className="mt-2 flex items-center gap-1 font-normal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="-mt-px h-4 w-4"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
            Search for Manual
          </Typography>
        </div>
      </div>
      <div className="md:hidden">
        <br />
      </div>
      <div className="m-2 p-2 lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-row justify-center align-middle">
        <table className=" w-full table-auto border border-slate-500 border-separate border-spacing-2">
          <thead>
            <tr>
              <th className="p-2 border border-slate-600">S.No</th>
              <th className="p-2 border border-slate-600">Name</th>
            </tr>
          </thead>
          <tbody>
            {manualData &&
              manualData.length > 0 &&
              manualData
                .filter((item) => {
                  if (search == "") {
                    return item;
                  } else if (
                    item.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="py-2 px-4 border border-slate-600">
                        {index + 1}
                      </td>
                      <td
                        onClick={() => {
                          handleNameClickBtn(item);
                        }}
                        className="py-2 px-4 border border-slate-600"
                      >
                        {item.name}
                      </td>
                      {/* <td className="p-4 border border-slate-600">
                      <img
                        src={item.imgSrc}
                        alt="prod image"
                        className="object-contain w-48 h-48"
                        onClick={() => {
                          handleImgClickBtn(item.name);
                        }}
                      />
                    </td> */}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddManuals;
