import React from 'react'
import { Input } from "@material-tailwind/react";

const InputField = ({handleChange,
    value,
    labelText,
    labelFor,
    id,
    name,
    type,
    isRequired=false,
    placeholder,
    label,
    customClass
}) => {

  return (
    <div className="my-5">
            {/* <label htmlFor={labelFor} className="sr-only py-5 px-5">
            {labelText} 
            </label> */}
            <Input
            className= "w-80 outline outline-offset-8 outline-gray-100"
              onChange={handleChange}
              value={value}
              id={id}
              name={name}
              type={type}
              required={isRequired}
              placeholder={placeholder}
              label = {label}
            />
          </div>
  );
};

export default InputField;