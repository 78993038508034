import React, { useEffect, useState } from "react";
import "firebase/firestore";
import { Input } from "@material-tailwind/react";

// import { db } from "../../../firebase";
// import { collection, doc, setDoc } from "firebase/firestore";
import ErrorMsg from "../AddProductComponents/ErrorMsg";
import { ADD_CATEGORY_API } from "../../Api";
import axios from "axios";

const AddCategory = ({ onClose }) => {
  const [name, setName] = useState(""); //category name
  const [description, setDescription] = useState(""); //category description
  // const [salesEmail, setSalesEmail] = useState(""); //category sales email
  // const [serviceEmail, setServiceEmail] = useState(""); //category service email
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  // const handleSalesEmailChange = (e) => {
  //   setSalesEmail(e.target.value);
  // };
  // const handleServiceEmailChange = (e) => {
  //   setServiceEmail(e.target.value);
  // };

  const handleSubmit = async () => {
    // console.log("Submit button clicked");

    //Check if all fields are filled
    if (
      name === "" ||
      description === "" 
      //  || salesEmail === "" ||
      // serviceEmail === ""
    ) {
      setErrorMsg("Please fill all the fields");
      setErrorPopup(true);
      return;
    }

    //add category to firestore
    // const collectionRef = collection(db, "Categories");
    // const categoryData = {
    //   name: name,
    //   id: name,
    //   desc: description,
    //   // salesEmail: salesEmail,
    //   // serviceEmail: serviceEmail,
    // };
    // await setDoc(doc(collectionRef, name), categoryData)
    //   .then(() => {
    //     console.log("Category added successfully");
    //     onClose();
    //   })
    //   .catch((error) => {
    //     console.error("Error adding category: ", error);
    //   });
    const formData = new FormData();
    formData.append("categoryName", name);
    formData.append("categoryDescription", description);
    console.log(formData.get("categoryName"));
    console.log(formData.get("categoryDescription"));

    try {
      const response = await axios.post(ADD_CATEGORY_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Category added successfully:", response.data);
    } catch (error) {
      console.error("Error uploading images:", error);
    } 
    finally {
      onClose();
    }
  };
  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClose]);

  return (
    <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white md:w-1/3 p-8 rounded-xl shadow-md">
        {errorPopup && (
          <ErrorMsg
            errMsg={errorMsg}
            onClose={() => setErrorPopup(false)}
          />
        )}
        <h2 className="text-lg font-semibold mb-4">Add Category</h2>
        <Input
          type="text"
          label="Name"
          onChange={handleNameChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />
        {/* <textarea
          type="text"
          rows={4}
          placeholder="Description"
          onChange={handleDescriptionChange}
          className=" rounded-lg border border-gray-400 text-md text-gray-600 p-2 mb-4 w-full"
        /> */}
        <div className="relative w-full min-w-[200px]">
          <textarea
            className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
            name="description"
            type="text"
            placeholder=""
            required
            rows={5}
            onChange={handleDescriptionChange}
          ></textarea>
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Description <span className=" text-red-600 pl-0.5"> *</span>
          </label>
        </div>
        <br />

        {/* <Input
          type="text"
          label="Sales E-mail"
          onChange={handleSalesEmailChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />

        <Input
          type="text"
          label="Service E-mail"
          onChange={handleServiceEmailChange}
          className="border border-gray-300 p-2 w-full"
        /> */}
        <br />

        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded mr-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
