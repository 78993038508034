import React, { useEffect, useState } from "react";
import logo from "../../assets/sun_tek_logo.png";
import { Link } from "react-router-dom";
import { FaTty, FaMobileAlt } from "react-icons/fa";
import { IoMailUnreadOutline } from "react-icons/io5";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FETCH_CATEGORIES_API } from "../Api";
//firebase imports
// import { db } from "../../firebase";
// import { getDocs, collection } from "firebase/firestore";
const Address = () => {
  const [categories, setCategories] = useState([]);
  const tempCategoryData = [];
  const currentYear = new Date().getFullYear();

  async function fetchCategoriesFromServer() {
    // const categoriesCollection = collection(db, "Categories"); // Reference to the 'categories' collection in Firestore

    // try {
    //   const querySnapshot = await getDocs(categoriesCollection); // Fetch all documents in the 'products' collection

    //   querySnapshot.forEach((item) => {
    //     const categoryData = item.data();

    //     tempCategoryData.push(categoryData);
    //   });
    //   setCategories(tempCategoryData);
    //   // Set the categories data in state
    // } catch (error) {
    //   console.error("Error fetching products:", error);
    // }

    try {
      const response = await fetch(FETCH_CATEGORIES_API);
      const categoriesData = await response.json();
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }
  useEffect(() => {
    fetchCategoriesFromServer(); // Fetch categories when the component mounts
  }, []);

  return (
    <div className="AdressBar">
      <div className=" md:px-8 lg:px-16  px-4 py-4 bg-gray-300 flex flex-col md:flex-row md:justify-between w-full">
        {/* Address  */}
        <a href="https://www.google.com/maps/place/SUN+TEKNOLOZY/@12.7892458,79.9090307,11z/data=!4m10!1m2!2m1!1ssunteknolozy!3m6!1s0x3a5267030c426185:0x98020b7b1e16afc2!8m2!3d12.7892458!4d80.2139013!15sCgxzdW50ZWtub2xvenmSARhlbnZpcm9ubWVudGFsX2NvbnN1bHRhbnTgAQA!16s%2Fg%2F113hc6hkh?entry=ttu">
          <div className="md:col-start-1 md:col-end-2 py-5">
            {/* <h1 class="container flex flex-row pl-2 pr-14 lg:pl-0 lg:pr-0 font-medium text-2xl leading-10 tracking-tight mb-3 text-gray-900 dark:text-gray-100 sm:leading-10 items-center text-center">
          Our<p className=" pl-2 text-orange-600 font-medium">address</p>
        </h1> */}
            <img
              className="object-cover w-60 mb-2 md:pb-2"
              src={logo}
              alt="cmpy_logo"
            />
            <div className="px-2 md:px-0 md:text-md text-sm">
              <div>
                <p className="font-bold font-times text-xl">SUN TEKNOLOZY</p>
              </div>
              <div className="py-3">
                <p>
                  <FaMapMarkedAlt className="w-4 h-4 mb-2" />
                  “STUDIO-10”, Vasavi Street,
                  <br /> Plot No. 32 & 33, Kamala Nagar, <br /> Kelambakkam,
                  Chennai-603103, <br />
                  Tamil Nadu, India
                </p>
              </div>
            </div>
          </div>
        </a>

        {/* Quick Links */}

        <div className="py-5 md:pl-32 md:pr-6">
          {/* <h1 class="container flex flex-row pl-2 pr-14 lg:pl-0 lg:pr-0 font-medium text-2xl leading-10 tracking-tight mb-3 text-gray-900 dark:text-gray-100 sm:leading-10 items-center text-center">
          Our<p className=" pl-2 text-orange-600 font-medium">address</p>
        </h1> */}
          <div className="px-2 md:px-0">
            <div>
              <h1 className="text-left md:text-center font-medium text-2xl">
                Quick
                <span className=" pl-2 text-green-600 font-medium">
                  Links
                </span>{" "}
              </h1>
            </div>

            <div className="text-left md:text-center container py-2 flex flex-col font-normal">
              <ul>
                {categories.map((category, index) => (
                  <div key={index}>
                    <li
                      className="py-3 text-sm font-kumbh font-medium"
                      key={index}
                    >
                      <Link
                        to={`/products/${category.id}`}
                        className="text-black hover:text-green-600"
                      >
                        {category.name}
                      </Link>
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Reach Us for Desktop */}

        <div className="hidden md:py-5 md:flex flex-col ">
          <h1 className=" md:text-right pl-2 pr-14 lg:pl-0 lg:pr-0 font-medium text-2xl leading-10 tracking-tight mb-3 text-gray-900 dark:text-gray-100 sm:leading-10">
            Reach<span className=" pl-2 text-green-600 font-medium">Us</span>
          </h1>

          {/* For sales contact */}
          <div className="px-2 md:px-0 md:text-md text-sm">
            <div className="container flex flex-row justify-between md:justify-center items-center">
              {/* <span className="py-6 text-lg font-bold"> For Service</span> */}
              <div className="container w-3/4 md:w-full flex flex-col md:justify-end md:items-end md:mr-11">
                <div className="container flex flex-row justify-between md:justify-end md:items-end">
                  <p className="font-medium py-1 pr-1">
                    admin@sunteknolozy.com
                  </p>
                  <div className=" w-9 container py-1 md:pl-2 flex justify-start">
                    <IoMailUnreadOutline className="w-4 md:w-5 h-4" />
                  </div>
                </div>
                <div className="flex flex-row justify-between md:justify-normal">
                  <p className="py-2 md:mr-1"> +91 - 9790935128</p>
                  <div className="container w-9 h-8 py-2  flex justify-start md:justify-center">
                    <FaMobileAlt className="w-4 h-4" />
                  </div>
                </div>

                {/* Landline number */}
                <div className="container flex flex-row justify-between md:justify-end">
                  <p className="py-2 md:pr-2 font-normal">044 - 35661951</p>

                  <div className=" w-9 py-2 flex justify-start md:justify-center">
                    <FaTty className="w-4 h-4" />
                  </div>
                </div>
              </div>
              <p className="py-6 pr-3 text-lg font-bold text-right">
                <span className="">Sales </span>
              </p>
            </div>
          </div>
          <br />
          {/* For service */}
          <div className="px-2 md:px-0 md:text-md text-sm">
            <div className="container flex flex-row justify-between md:justify-center items-center">
              {/* <span className="py-6 text-lg font-bold"> For Service</span> */}
              <div className="container w-3/4 md:w-full flex flex-col md:justify-end md:items-end md:mr-6">
                <div className="container flex flex-row justify-between md:justify-end md:items-end">
                  <p className="font-medium py-1 pr-1">
                    service@sunteknolozy.com
                  </p>
                  <div className=" w-9 container py-1 md:pl-2 flex justify-start">
                    <IoMailUnreadOutline className="w-4 md:w-5 h-4" />
                  </div>
                </div>
                <div className="flex flex-row justify-between md:justify-normal">
                  <p className="py-2 md:mr-1"> +91 - 7598725128</p>
                  <div className="container w-9 h-8 py-2  flex justify-start md:justify-center">
                    <FaMobileAlt className="w-4 h-4" />
                  </div>
                </div>

                {/* Landline number */}
                <div className="container flex flex-row justify-between md:justify-end">
                  <p className="py-2 md:pr-2 font-normal">044 - 35661951</p>

                  <div className=" w-9 py-2 flex justify-start md:justify-center">
                    <FaTty className="w-4 h-4" />
                  </div>
                </div>
              </div>
              <p className="py-6 pr-4 text-lg font-bold text-right">
                 <span className="">Service </span>
              </p>
            </div>
          </div>
        </div>

        {/* Reach Us for Mobile */}

        <div className="md:hidden md:py-5 flex flex-col ">
          <h1 className=" md:text-right pl-2 pr-14 lg:pl-0 lg:pr-0 font-medium text-2xl leading-10 tracking-tight mb-3 text-gray-900 dark:text-gray-100 sm:leading-10">
            Reach<span className=" pl-2 text-green-600 font-medium">Us</span>
          </h1>

          {/* For sales contact */}
          <div className="px-2 md:px-0 md:text-md text-sm">
            <div className="container flex flex-row justify-between md:justify-center items-center">
              <p className="py-6 pr-4 text-lg font-bold text-left">
                 <span className="text-base">Sales </span>
              </p>
              {/* <span className="py-6 text-lg font-bold"> For Service</span> */}
              <div className="container w-3/4 md:w-full flex flex-col md:justify-end md:items-end md:mr-11">
                <div className="container flex flex-row justify-start md:justify-end md:items-end">
                  <div className=" w-9 container py-1 md:pl-2 flex justify-start">
                    <IoMailUnreadOutline className="w-4 md:w-5 h-4" />
                  </div>
                  <p className="font-medium py-1">admin@sunteknolozy.com</p>
                </div>
                <div className="flex flex-row justify-start md:justify-normal">
                  <div className="container w-9 h-8 py-2  flex justify-start md:justify-center">
                    <FaMobileAlt className="w-4 h-4" />
                  </div>
                  <p className="py-2 md:mr-1"> +91 - 9790935128</p>
                </div>

                {/* Landline number */}
                <div className="container flex flex-row justify-start md:justify-end">
                  <div className=" w-9 py-2 flex justify-start md:justify-center">
                    <FaTty className="w-4 h-4" />
                  </div>
                  <p className="py-2 md:pr-2 font-normal">044 - 35661951</p>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* For service */}
          <div className="px-2 md:px-0 md:text-md text-sm">
            <div className="container flex flex-row justify-between md:justify-center items-center">
              <p className="py-6 text-lg font-bold text-left">
                 <span className=" text-base">Service</span>
              </p>
              {/* <span className="py-6 text-lg font-bold"> For Service</span> */}
              <div className="container w-3/4 md:w-full flex flex-col md:justify-end md:items-end md:mr-6">
                <div className="container flex flex-row justify-start md:justify-end md:items-end">
                  
                  <div className=" w-9 container py-1 md:pl-2 flex justify-start">
                    <IoMailUnreadOutline className="w-4 md:w-5 h-4" />
                  </div>
                  <p className="font-medium py-1 pr-1">
                    service@sunteknolozy.com
                  </p>
                </div>
                <div className="flex flex-row justify-start md:justify-normal">
                  
                  <div className="container w-9 h-8 py-2  flex justify-start md:justify-center">
                    <FaMobileAlt className="w-4 h-4" />
                  </div>
                  <p className="py-2 md:mr-1"> +91 - 7598725128</p>
                </div>

                {/* Landline number */}
                <div className="container flex flex-row justify-start md:justify-end">
                  <div className=" w-9 py-2 flex justify-start md:justify-center">
                    <FaTty className="w-4 h-4" />
                  </div>
                  <p className="py-2 md:pr-2 font-normal">044 - 35661951</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-2 px-4 text-xs flex justify-center md:justify-end bg-gray-800 text-gray-300">

        <span>
          {" "}
          &copy; Copyright {currentYear} - All Rights Reserved by Sun Teknolozy
        </span>
      </div>
    </div>
  );
};

export default Address;
