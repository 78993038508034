import React, { useState, useEffect } from 'react';
import { FaFacebook, FaLinkedinIn } from 'react-icons/fa';

function SocialMediaButtons() {

    const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down 400px
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 400) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
      // check if the window is at the bottom of the page
      if ((window.innerHeight + window.scrollY + 250) >= document.body.offsetHeight) {
        setIsVisible(false);
      }
      
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

    // Scroll to top when button is clicked
    const openNewFbTab = () => {
        // Replace 'https://example.com' with the URL you want to open in the new tab
        const url = 'https://facebook.com';
        window.open(url, '_blank');
    };
    const openNewLiTab = () => {
        // Replace 'https://example.com' with the URL you want to open in the new tab
        const url = 'https://www.linkedin.com/company/sun-teknolozy/mycompany/';
        window.open(url, '_blank');
    };

    return (
        <div>
            {( isVisible && 
                <button 
                className="fixed opacity-50 right-10 md:right-20 bottom-24 z-1 cursor-pointer"
                onClick={openNewFbTab}>
                    <FaFacebook className='top-btn--icon h-6 w-6 md:h-8 md:w-8' />
                    
                </button>
            )}
            { ( isVisible &&
                <button 
                className="fixed opacity-50 right-10 md:right-20 bottom-10 z-1 cursor-pointer"
                onClick={openNewLiTab}>
                    <FaLinkedinIn className='top-btn--icon h-6 w-6 md:h-8 md:w-8' />
                    
                </button>
            )}
        </div>
    );
}

export default SocialMediaButtons;

