import { Input } from "@material-tailwind/react";
import React, { useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { MdOutlineDelete } from "react-icons/md";
import { UPLOAD_MANUAL_API } from "../../Api";
import axios from "axios";
import ErrorMsg from "../AddProductComponents/ErrorMsg";

const AddManual = ({ onClose }) => {
  const [text, setText] = useState("");
  const [pdf, setPdf] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgPopup, setErrorMsgPopup] = useState(false);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };
  const handleSubmit = async () => {
    console.log("Submit button clicked");
    if (pdf === null || text === "") {
        setErrorMsg("Please fill all the fields");
        setErrorMsgPopup(true);
      return;
    }
    const formData = new FormData();
    formData.append("manualName", text);
    formData.append("pdfFile", pdf);
    try {
      const response = await axios.post(
        UPLOAD_MANUAL_API,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

    if (response.status === 200) {
      console.log("File uploaded successfully");
    }
    else if (response.status = 202){
        console.log("File already exists");
        setErrorMsg("File already exists");
        setErrorMsgPopup(true);
    }
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMsg("Error uploading file");
        setErrorMsgPopup(true);
    }
  };
  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    setPdf(file);
  };
  const handlePdfRemoval = () => {
    setPdf(null);
  };
  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-xl shadow-md">
        {
            errorMsgPopup && (
                <ErrorMsg
                    errMsg={errorMsg}
                    onClose={() => {
                        setErrorMsgPopup(false);
                    }}
                />
            )
        }
        <h2 className="text-lg font-semibold mb-4">Add Manual</h2>
        <Input
          type="text"
          required
          label="Manual Name"
            onChange={handleTextChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />
        <label className="text-xs md:text-md text-gray-600 ml-2">PDF <span className=" text-red-600 pl-0.5"> *</span></label>
        <div
          className="image-preview grid grid-cols-3 gap-2
            md:grid-cols-4 lg:grid-cols-5 md:gap-4"
        >
          {pdf && (
            <div className="relative mb-4 flex flex-row justify-center items-center">
              <p className="text-xs md:text-md text-gray-600 ml-2">
                {pdf.name.trim().substring(0, 10)}
              </p>
              <button
                onClick={() => {
                  handlePdfRemoval();
                }}
                className=" w-4 h-4 bg-white
                    absolute top-0 right-0 -mr-1 md:mr-0
                    text-white p-2 rounded-full"
              >
                <MdOutlineDelete
                  className="text-red-500
                        absolute top-0 right-0 "
                />
              </button>
            </div>
          )}
          <label className="text-xs md:text-md text-gray-600">
            <div className="h-full flex justify-start items-center">
              <div className="relative mt-2">
                <input
                  type="file"
                  id="pdf"
                  multiple
                  accept="pdf/*"
                  onChange={handlePdfChange}
                  className="sr-only"
                />
                <div
                  htmlFor="image"
                  className="cursor-pointer hover:bg-gray-500
               bg-gray-300 w-14 h-14 m-1 p-1 text-white px-4 
                flex justify-center items-center rounded-full"
                >
                  <IoIosAdd className="w-10 h-10 text-gray-800" />
                </div>
              </div>
            </div>
          </label>

          {/* <img
            src="https://images.unsplash.com/photo-1702573849838-0f66f3731fdf?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className=" m-1 p-1 w-20 h-20 object-cover"
          /> */}
        </div>
        <br />
        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded mr-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddManual;
