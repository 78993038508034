import React from 'react'
import NewNav from '../navbar/NewNav'
import ProductsDescription from './ProductsDescription'
import Address from '../address/Address'

const ProductDetail = () => {
  return (
   <div>
    <NewNav />
    <ProductsDescription />
    <Address />
   </div>
  )
}

export default ProductDetail