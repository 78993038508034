import React from "react";
import Hero from "./hero/Hero";
import About from "./about/About";
import Clients from "./clients/Clients";
import Address from "../address/Address";
import BusinessVerticals from "./businessVerticals/BusinessVerticals";
import CountDown from "./countDown/CountDown";
import NewNav from "../navbar/NewNav";
// Home Page
const Home = () => {
  return (
    <div>
      <NewNav />
      <Hero />
      <About />
      <BusinessVerticals />
      <CountDown />
      <Clients />
      {/* <QuickLinks /> */}
      <Address />
    </div>
  );
};

export default Home;
