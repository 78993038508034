import React, { useEffect, useState } from "react";
// import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ChildItem from "./ChildItem";
// import { getDocs, collection } from "firebase/firestore";
// import { db } from "../../../firebase";
import sun_science_logo from "../../../assets/sun_scien_logo.png";
import sun_lab_logo from "../../../assets/sun_lab_logo.png";
import { FETCH_CLIENTS_API, FETCH_TESTIMONIAL_API } from "../../Api";
import Slider from "react-slick";
import { settings } from "./TestimonialCarousel";
import { settingsClientsRev } from "./ReverseClient";
import "slick-carousel/slick/slick.css";
import { FaQuoteLeft } from "react-icons/fa6";
import "slick-carousel/slick/slick-theme.css";
import { settingsClients } from "./ClientCarousel";
// import sun_tek_logo from "../../../assets/sun_tek_logo.png";

// const responsive = {
//   0: { items: 1 },
//   350: { items: 4 },
//   500: { items: 6 },
//   1024: { items: 8 },
// };

// const items = [
//   <div className="container item" data-value="1">
//     <ChildItem
//       imageUrl="https://cdn-icons-png.flaticon.com/512/6009/6009864.png"
//       overlayText=""
//     />
//   </div>,
// ];

const Clients = () => {
  const [clientsData, setClientsData] = useState([]);
  const [testimonials, setTestimonials] = useState([]);

  // const tempClientsData = [];

  const newLogos = clientsData
    ? clientsData.map((client,index) => {
        return (
          <div
          key={index}
          className="container m-1 item" data-value="1">
            <ChildItem
              imageUrl={client.imgSrc} //"https://www.crescentindustrial.co.uk/wp-content/uploads/2021/07/HV1200-UV-900x600-1.png"
              overlayText=""
            />
          </div>
        );
      })
    : [];

  async function fetchClientsFromServer() {
    // const clientsCollection = collection(db, "Clients"); // Reference to the 'products' collection in Firestore

    // try {
    //   const querySnapshot = await getDocs(clientsCollection); // Fetch all documents in the 'products' collection

    //   querySnapshot.forEach((item) => {
    //     const clientData = item.data();
    //     tempClientsData.push(clientData);
    //   });
    //   setClientsData(tempClientsData);
    //   // Set the products data in state
    // } catch (error) {
    //   console.error("Error fetching clients:", error);
    // }
    try {
      const response = await fetch(FETCH_CLIENTS_API);
      const clientsData = await response.json();
      setClientsData(clientsData);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  }
  async function fetchTestimonialsFromServer() {
    try {
      const response = await fetch(FETCH_TESTIMONIAL_API);
      const testimonialsData = await response.json();
      setTestimonials(testimonialsData);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  }

  useEffect(() => {
    fetchClientsFromServer(); // Fetch clients when the component mounts
    fetchTestimonialsFromServer();
  }, []);

  return (
    <div className="2xl:container 2xl:mx-auto lg:py-10 lg:px-26 md:py-8 md:px-6 py-5 px-4">
      {/* Clients */}
      <div className="md:h-full flex flex-col justify-evenly">
        <h1
          className=" text-center lg:pl-0 lg:pr-0 text-2xl font-extrabold 
        leading-10 tracking-tight mb-3 md:mb-6 lg:mb-9 text-gray-900
         dark:text-gray-100 sm:text-4xl sm:leading-10 items-center md:text-4xl md:leading-normal "
        >
          We<span className="text-green-500 font-medium"> Serve</span>
        </h1>

        <div>
          {/* <AliceCarousel
            disableButtonsControls
            disableDotsControls
            disableSlideInfo
            autoPlay
            autoPlayStrategy="none"
            animationType="slide"
            infinite
            autoPlayInterval={0}
            animationDuration={3000}
            items={newLogos.slice(0, 20)}
            responsive={responsive}
            syncStateOnPropsUpdate={false}
          />

          /> */}

          <Slider {...settingsClients}>{newLogos.slice(0,50)}</Slider>
          <Slider {...settingsClientsRev}>{newLogos.slice(51,100)}</Slider>
          <Slider {...settingsClients}>{newLogos.slice(101,newLogos.length-1)}</Slider>
        </div>
      </div>
      <div className="hidden md:block"></div>
      {/* Testimonials */}
      {/* Testimonials 3 cards where each card has a profile photo in the top center and text content below the profile photo*/}
      {testimonials && testimonials.length > 3 && (
        <div className="flex flex-col justify-evenly my-6 md:my-10 lg:my-12">
          <h1
            className=" text-center lg:pl-0 lg:pr-0 text-2xl font-extrabold leading-10 
    tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10
     items-center md:text-4xl md:leading-normal "
          >
            Our <span className=" text-green-500 font-medium"> Testimonials </span>
          </h1>
          <br />
          <br />
          <Slider {...settings}>
            {testimonials &&
              testimonials.map((testimonial,index) => {
                return (
                  <div 
                  key={index}
                  className="max-w-xs m-2 mx-auto flex flex-col justify-center items-center overflow-hidden rounded-lg shadow-lg">
                    <div className="relative overflow-hidden flex flex-row justify-center align-middle pb-2/3">
                      <img
                        className="object-cover w-32 h-32 rounded-full"
                        src={testimonial.imgSrc}
                        alt={`profile img`}
                      />
                    </div>
                    <div className="p-4 bg-white flex flex-col items-center rounded-b-lg">
                      <h3 className="text-xl font-semibold mb-2">
                        {testimonial.name}
                      </h3>
                      <p className="text-gray-700 font-medium mb-2">{testimonial.designation}</p>
                      <p className="text-gray-700 mb-2 font-kumbh font-semibold">{testimonial.organization}</p>
                      <p className="text-gray-600 mb-2"><FaQuoteLeft/></p>
                      <p className="text-gray-800 text-sm">
                        {testimonial.desc}
                      </p>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      )}
      <br />

      {/* Our companies */}
      <div className="flex flex-col justify-evenly my-6 md:my-10 lg:my-12">
        <h1
          className=" text-center lg:pl-0 lg:pr-0 text-2xl font-extrabold leading-10 
        tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10
         items-center md:text-4xl md:leading-normal "
        >
          Our <span className=" text-green-500 font-medium"> Wings</span>
        </h1>
        <br />
        <br />
        <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-evenly">
          <div className="p-2 w-4/5 lg:w-2/6">
            <img src={sun_lab_logo} alt="" />
          </div>
          <div className="p-2 w-4/5 lg:w-2/6">
            <img src={sun_science_logo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
