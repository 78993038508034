import React, { useEffect, useState } from "react";
import SignOutBtn from "../SignOutBtn/SignOutBtn";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { Input, Typography } from "@material-tailwind/react";
import classNames from "classnames";
import { DELETE_TESTIMONIAL_API, FETCH_TESTIMONIAL_API } from "../../Api";
import axios from "axios";
import DeleteConfirmation from "../AddClientCompononents/DeleteConfirmation";
import ErrorMsg from "../AddProductComponents/ErrorMsg";
import AddTestimonial from "./AddTestimonial";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AddTestimonials = () => {
  const [search, setSearch] = useState(""); //search typing state
  const [testimonialData, setTestimonialData] = useState(null); //testimonial data state
  const [selectedTestimonial, setSelectedTestimonial] = useState(false); //selected testimonial state
  const [testimonialName, setTestimonialName] = useState(""); //testimonial name state
  const [selectedTestimonialId, setSelectedTestimonialId] = useState(false); //selected testimonial id state
  const [selectedTestimonialIndex, setSelectedTestimonialIndex] =
    useState(false); //selected testimonial index state
  const [isDeleteClicked, setIsDeleteClicked] = useState(false); //delete button state
  const [errorMsgPopup, setErrorMsgPopup] = useState(false); //error message state
  const [errorMsg, setErrorMsg] = useState(""); //error message state
  const [addTestimonial, setAddTestimonial] = useState(false); //add testimonial state

  //  check if user is authenticated
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, []);

  // decorative function for delete button
  const getItemClassesDelete = () =>
    classNames("cursor-pointer", {
      "bg-red-400": selectedTestimonialIndex,
      "bg-gray-100": !selectedTestimonialIndex,
      "font-bold": selectedTestimonialIndex,
      "font-normal": !selectedTestimonialIndex,
      "transform transition-all scale-110": selectedTestimonialIndex,
    });

  const handleaddTestimonialBtn = () => {
    setAddTestimonial(!addTestimonial);
  };
  const handleImgClickBtn = (manual) => {
    console.log("Clicked : ", manual.name);
    setSearch(manual.name);
    setTestimonialName(manual.name);
    setSelectedTestimonialIndex(!selectedTestimonialIndex);
    setSelectedTestimonialId(manual.id);
    if (selectedTestimonialIndex) {
      setSearch("");
    }

    if (manual.id === selectedTestimonialId) {
      setSelectedTestimonialId(null);
      setSelectedTestimonialIndex(false);
      setSelectedTestimonial(false);
      setTestimonialName("");
    }
  };

  const deleteTestimonial = async () => {
    try {
      const response = await axios.delete(
        DELETE_TESTIMONIAL_API + selectedTestimonialId
      );
      if (response.status === 200) {
        console.log("Manual deleted successfully");
        fetchTestimonials();
      } else if ((response.status = 202)) {
        console.log("Manual does not exist");
        setErrorMsg("Manual does not exist");
        setErrorMsgPopup(true);
      }
      setSelectedTestimonial(!selectedTestimonial);
    } catch (error) {
      console.error("Error deleting file:", error);
      setErrorMsg("Error deleting file");
      setErrorMsgPopup(true);
    }
  };

  const fetchTestimonials = async () => {
    try {
      const response = await fetch(FETCH_TESTIMONIAL_API);
      const data = await response.json();
      if (response.status === 404) {
        setErrorMsg(response.statusText);
        setErrorMsgPopup(true);
        return;
      }
      setTestimonialData(data);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
      // setErrorMsg("Error fetching testimonials");
      // setErrorMsgPopup(true);
    }
  };

  const handleDeleteBtn = () => {
    if (selectedTestimonialId === null) {
      return;
    }
    setSelectedTestimonial(!selectedTestimonial);
    setIsDeleteClicked(!isDeleteClicked);
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setSelectedTestimonialIndex(false);
        setSearch("");
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedTestimonialIndex]);

  // console.log("Testimonial data: ", testimonialData);
  return (
    <div>
      <SignOutBtn />
      {errorMsgPopup && (
        <ErrorMsg
          message={errorMsg}
          onClose={() => {
            setErrorMsgPopup(false);
          }}
        />
      )}
      {addTestimonial && (
        <AddTestimonial
          onClose={() => {
            setAddTestimonial(!addTestimonial);
            fetchTestimonials();
          }}
        />
      )}
      {selectedTestimonial && selectedTestimonialId && isDeleteClicked && (
        <DeleteConfirmation
          onConfirm={() => {
            deleteTestimonial();
            setSelectedTestimonialIndex(false);
            setSelectedTestimonial(false);
            setSearch("");
          }}
          onCancel={() => {
            setSelectedTestimonialIndex(false);
            setSelectedTestimonialId(null);
            setSelectedTestimonial(false);
            setSearch("");
          }}
        />
      )}

      <div>
        <h2 className="mt-20 mb-20 text-center text-2xl md:text-3xl font-extrabold text-gray-900">
          Add Testimonials
        </h2>
      </div>
      <div className=" Buttons lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-row justify-around align-middle">
        <div
          className=" m-1 px-4 w-1/6 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg  h-auto hover:cursor-default transform transition-all hover:scale-110"
          onClick={() => {
            handleaddTestimonialBtn();
          }}
        >
          <p className="hidden md:block text-center text-md">Add Testimonial</p>
          <IoIosAddCircleOutline className="md:hidden w-9" />
        </div>

        <div
          onClick={() => {
            handleDeleteBtn();
          }}
          className={` m-1 px-4 rounded-2xl flex flex-col 
        justify-center items-middle bg-gray-100 ${getItemClassesDelete()}
      hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto
        hover:cursor-default transform transition-all hover:scale-110`}
          //className=" m-1 px-4 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto hover:cursor-default transform transition-all hover:scale-110"
          //   onClick={() => {
          //     handleClick(index);
          //   }}
        >
          <p className="hidden md:block text-center text-md">
            Delete Testimonial
          </p>
          <MdDeleteOutline className="md:hidden w-9 " />
        </div>

        <div className=" m-1 md:px-4 rounded-2xl hover:drop-shadow-lg w-min md:w-3/6 h-11 hover:cursor-default">
          <Input
            label="Search"
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Typography
            variant="small"
            color="gray"
            className="mt-2 flex items-center gap-1 font-normal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="-mt-px h-4 w-4"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
            Search for Testimonial
          </Typography>
        </div>
      </div>
      <div className="md:hidden">
        <br />
      </div>
      <div className="m-2 p-2 lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-row justify-center align-middle">
        <table className=" w-full table-auto border border-slate-500 border-separate border-spacing-2">
          <thead>
            <tr>
              <th className="p-2 border border-slate-600">S.No</th>
              <th className="p-2 border border-slate-600">Name</th>
              <th className="p-2 hidden md:block border border-slate-600">
                Designation
              </th>
              <th className="p-2 hidden md:block border border-slate-600">Organization</th>

              <th className="hidden md:block p-2 border border-slate-600">
                Description
              </th>
              <th className="p-2 border border-slate-600">Image</th>
            </tr>
          </thead>
          <tbody>
            {testimonialData &&
              testimonialData.length > 0 &&
              testimonialData
                .filter((item) => {
                  if (search === "") {
                    return item;
                  } else if (
                    item.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="py-2 px-4 border border-slate-600">
                        {index + 1}
                      </td>
                      <td className="py-2 px-4 border border-slate-600">
                        {item.name}
                      </td>
                      <td className="hidden md:block py-2 px-4 border border-slate-600">
                        {item.designation}
                      </td>
                      <td className="py-2 hidden md:block px-4 border border-slate-600">
                        {item.organization}
                      </td>
                      <td className="hidden md:block py-2 px-4 border border-slate-600">
                        {item.desc}
                      </td>
                      <td className="p-4 border border-slate-600">
                        <img
                          src={item.imgSrc}
                          alt="Testimonial"
                          className="object-contain w-48 h-48"
                          onClick={() => {
                            handleImgClickBtn(item);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddTestimonials;
