// AddItem.js
import React, { useState } from "react";
import "firebase/firestore";
import { Input } from "@material-tailwind/react";
// import { storage, db } from "../../../firebase";
// import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// import { doc, setDoc } from "firebase/firestore";
import { MdOutlineDelete } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import ErrorMsg from "../AddProductComponents/ErrorMsg";
import axios from "axios";
import { UPLOAD_CLIENT_API } from "../../Api";

const AddClient = ({ onClose }) => {
  // var tempUrl = "";
  // const [isMediaUploaded, setIsMediaUploaded] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  // const [imgUrl, setImgUrl] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [errorMsgPopup, setErrorMsgPopup] = useState(false);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleImageRemoval = () => {
    setImage(null);
  };

  const handleSubmit = async () => {

    if (image === null || text === "") {
      setErrorMsg("Please fill all the fields");
      setErrorMsgPopup(true);
      return;
    }
    // try {
    //   // Upload image to Firebase Storage (you need to set up Firebase Storage)

    //   const storageRef = ref(storage, `clientsLogo/${image.name}`);
    //   //   await storageRef.put(image);
    //   //   const imageUrl = await storageRef.getDownloadURL();

    //   const uploadTask = uploadBytesResumable(storageRef, image);
    //   uploadTask.on(
    //     "state_changed",
    //     (snapshot) => {
    //       const progress = Math.round(
    //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //       );
    //       console.log(progress);
    //     },
    //     (err) => {
    //       console.log(err);
    //     },
    //     () => {
    //       getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //         // console.log("Inside download method: " + url);
    //         setImgUrl(url);
    //         setIsMediaUploaded(true);
    //         // console.log("LOOOOOOOOOK: " + imgUrl);
    //       });
    //     }
    //   );
    //   // Close the add item popup
    // } catch (error) {
    //   console.error("Error adding item:", error);
    // }
    // console.log("Submit button clicked");
    const formData = new FormData();
    formData.append("clientName", text);
    formData.append("clientLogo", image);
    
    try {
      const response = await axios.post(UPLOAD_CLIENT_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Product added successfully:", response.data);
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      onClose();
    }
  };

  const handleCancel = () => {
    // Close the add item popup without adding an item
    onClose();
  };
  // async function uploadClientData() {
  //   // console.log("Inside uploadClientData method");
  //   // console.log("LOOOOOOOOOK: " + imgUrl);
  //   if (isMediaUploaded) {
  //     // Add item to Firestore
  //     await setDoc(doc(db, "Clients", text), {
  //       name: text,
  //       imgSrc: imgUrl,
  //     })
  //       .then(() => {
  //         console.log("{Document} successfully written!");
  //         setIsMediaUploaded(false);
  //         onClose();
  //       })
  //       .catch((error) => {
  //         console.error("Error writing document: ", error);
  //       });
  //   }
  // }

  // useEffect(() => {
  //   uploadClientData();
  // }, [imgUrl]);

  return (
    <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-xl shadow-md">
      {errorMsgPopup && (
            <ErrorMsg
              errMsg= {errorMsg}
              onClose={() => {
                setErrorMsgPopup(false);
              }}
            />
          )}
        <h2 className="text-lg font-semibold mb-4">Add Client</h2>

        <Input
          type="text"
          required
          label="Name"
          onChange={handleTextChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />

        {/* <Input
          label="Image"
          required
          type="file"
          accept=".jpg, .jpeg, .png"
          onChange={handleImageChange}
          className="border border-gray-300 p-2 w-full"
        /> */}

        <label className="text-xs md:text-md text-gray-600 ml-2">Image <span className=" text-red-600 pl-0.5"> *</span></label>
        <div
          className="image-preview grid grid-cols-2 gap-2
            md:grid-cols-3 lg:grid-cols-5 md:gap-4"
        >
          {image && (
            <div className="relative inline-block mb-4">
              <img
                src={URL.createObjectURL(image)}
                alt="Product"
                className="w-20 h-20 m-1 p-1 object-cover rounded-xl"
              />
              <button
                onClick={() => {
                  handleImageRemoval();
                }}
                className=" w-4 h-4 bg-white
                    absolute top-0 right-0 -mr-1 md:mr-0
                    text-white p-2 rounded-full"
              >
                <MdOutlineDelete
                  className="text-red-500
                        absolute top-0 right-0 "
                />
              </button>
            </div>
          )}
          <label className="text-xs md:text-md text-gray-600">
            <div className="h-full flex justify-start items-center">
              <div className="relative mt-2">
                <input
                  type="file"
                  id="image"
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                  className="sr-only"
                />
                <div
                  htmlFor="image"
                  className="cursor-pointer hover:bg-gray-500
               bg-gray-300 w-14 h-14 m-1 p-1 text-white px-4 
                flex justify-center items-center rounded-full"
                >
                  <IoIosAdd className="w-10 h-10 text-gray-800" />
                </div>
              </div>
            </div>
          </label>

          {/* <img
            src="https://images.unsplash.com/photo-1702573849838-0f66f3731fdf?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className=" m-1 p-1 w-20 h-20 object-cover"
          /> */}
        </div>
        <br />

        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded mr-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
