import React from 'react';

//Load every image as an item
const ChildItem = ({ imageUrl, overlayText }) => {
  return (
    <div className="lg:mx-4 md:mx-2 px-1 lg:my-4 my-4 w-2/4 h-2/4">
      <img src={imageUrl} alt="Item" className=" object-cover" />
      {/* <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
        {overlayText}
      </div> */}
    </div>
  );
};

export default ChildItem;
