import SignOutBtn from "../SignOutBtn/SignOutBtn";
import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Input, Option, Select, Typography } from "@material-tailwind/react";

//firebase imports
// import { db } from "../../../firebase";
// import { getDocs, collection } from "firebase/firestore";
import AddProduct from "./AddProduct";
import ErrorMsg from "./ErrorMsg";
import EditProduct from "./EditProduct";

import { IoIosAddCircleOutline } from "react-icons/io";
import {
  FETCH_CATEGORIES_API,
  FETCH_SUBCATEGORIES_API,
  FETCH_SUBCATEGORY_PRODUCTS_API,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AddProducts = () => {
  //check if user is authenticated
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, []);
  const [categories, setCategories] = useState([]);
  const tempCategoryData = [];

  const [search, setSearch] = useState("");

  const [subCategories, setSubCategories] = useState([]);
  const tempSubCategoryData = [];

  const [categoryName, setcategoryName] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState(null);

  const [products, setProducts] = useState([]);
  const [prodInfo, setProdInfo] = useState(null); //product info [name, desc, feat, img
  const [addProduct, setAddProduct] = useState(false); //toggle btn for add product
  const [editProduct, setEditProduct] = useState(false); //toggle btn for edit product
  const tempProductsData = [];

  const handleAddProductBtn = () => {
    setAddProduct(!addProduct);
  };

  const handleImgClickBtn = (product) => {
    setProdInfo(product);
    setEditProduct(!editProduct);
  };

  const handleCategoryClick = (category) => {
    setcategoryName(category);
    fetchSubCategoriesFromServer(category.id);
  };
  const handleSubCategoryClick = (subCategory) => {
    setSubcategoryName(subCategory);
    fetchProductsFromServer(subCategory.id);
  };
  // async function fetchProductsFromFirestore() {
  //   if (!categoryName || !subcategoryName) {
  //     return;
  //   }
  //   const productsCollection = collection(
  //     db,
  //     "Categories",
  //     categoryName.id,
  //     "SubCategories",
  //     subcategoryName.id,
  //     subcategoryName.id + "_Products"
  //   ); // Reference to the 'categories' collection in Firestore
  //   try {
  //     console.log("Inside fetchProductsFromFirestore");
  //     const querySnapshot = await getDocs(productsCollection); // Fetch all documents in the 'products' collection

  //     querySnapshot.forEach((item) => {
  //       console.log("Item data: ", item);
  //       const productData = item.data();
  //       console.log(productData.name);
  //       tempProductsData.push(productData);
  //     });
  //     setProducts(tempProductsData);
  //     // Set the categories data in state
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // }

  // async function fetchSubCategoriesFromFirestore() {
  //   if (!categoryName) {
  //     return;
  //   }
  //   const subCategoriesCollection = collection(
  //     db,
  //     "Categories",
  //     categoryName.id,
  //     "SubCategories"
  //   ); // Reference to the 'categories' collection in Firestore
  //   try {
  //     console.log("Inside fetchSubCategoriesFromFirestore");
  //     const querySnapshot = await getDocs(subCategoriesCollection); // Fetch all documents in the 'products' collection

  //     querySnapshot.forEach((item) => {
  //       console.log("Item data: ", item);
  //       const subCategoryData = item.data();
  //       console.log(subCategoryData.name);
  //       tempSubCategoryData.push(subCategoryData);
  //     });
  //     setSubCategories(tempSubCategoryData);
  //     // Set the categories data in state
  //   } catch (error) {
  //     console.error("Error fetching sub-categories:", error);
  //   }
  // }

  async function fetchCategoriesFromServer() {
    try {
      const response = await fetch(FETCH_CATEGORIES_API);
      const categoriesData = await response.json();
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }
  async function fetchProductsFromServer(subcategoryId) {
    if (!categoryName) {
      return;
    }
    try {
      const response = await fetch(
        FETCH_SUBCATEGORY_PRODUCTS_API + categoryName.id + "/" + subcategoryId
      );
      const productsData = await response.json();
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }

  // async function fetchCategoriesFromFirestore() {
  //   const categoriesCollection = collection(db, "Categories"); // Reference to the 'categories' collection in Firestore

  //   try {
  //     const querySnapshot = await getDocs(categoriesCollection); // Fetch all documents in the 'products' collection

  //     querySnapshot.forEach((item) => {
  //       console.log(item);
  //       const categoryData = item.data();
  //       console.log(categoryData.name);
  //       tempCategoryData.push(categoryData);
  //     });
  //     setCategories(tempCategoryData);
  //     // Set the categories data in state
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // }

  async function fetchSubCategoriesFromServer(categoryId) {
    try {
      const response = await fetch(FETCH_SUBCATEGORIES_API + categoryId);
      const subCategoriesData = await response.json();
      setSubCategories(subCategoriesData);
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  }

  useEffect(() => {
    fetchCategoriesFromServer(); // Fetch categories when the component mounts
  }, []);

  // useEffect(() => {
  //   fetchSubCategoriesFromFirestore(); // Fetch  sub-categories when the component mounts
  // }, [categoryName]);

  // useEffect(() => {
  //   fetchProductsFromFirestore(); // Fetch products when the component mounts
  // }, [subcategoryName, categoryName]);

  return (
    <div>
      <SignOutBtn />
      {addProduct &&
        (categoryName ? (
          subcategoryName ? (
            (console.log(categoryName.id),
            console.log(subcategoryName.id),
            (
              <AddProduct
                categoryId={categoryName.id}
                subCategoryId={subcategoryName.id}
                onClose={() => {
                  setAddProduct(false);
                  fetchProductsFromServer(subcategoryName.id);
                }}
              />
            ))
          ) : (
            <ErrorMsg
              errMsg="Please select a Sub-category"
              onClose={() => {
                setAddProduct(false);
              }}
            />
          )
        ) : (
          <ErrorMsg
            errMsg="Please select a Category"
            onClose={() => {
              setAddProduct(false);
            }}
          />
        ))}
      {editProduct &&
        (categoryName ? (
          subcategoryName ? (
            (console.log(categoryName.id),
            console.log(subcategoryName.id),
            (
              <EditProduct
                prodData={prodInfo}
                categoryId={categoryName.id}
                subCategoryId={subcategoryName.id}
                onClose={() => {
                  setEditProduct(false);
                  setProdInfo(null);
                  fetchProductsFromServer(subcategoryName.id);
                }}
              />
            ))
          ) : (
            <ErrorMsg
              errMsg="Please select a Sub-category"
              onClose={() => {
                setAddProduct(false);
              }}
            />
          )
        ) : (
          <ErrorMsg
            errMsg="Please select a Category"
            onClose={() => {
              setAddProduct(false);
            }}
          />
        ))}
      <div>
        <h2
          className="mt-20 mb-8 md:mb-10 text-center text-2xl md:text-3xl
        font-extrabold text-gray-900"
        >
          Add or Edit Products
        </h2>
      </div>

      {/* Category - sub-category info */}
      <div>
        {/* <h1 className=" text-center md:text-xl text-sm font-extrabold font-sans md:py-10 py-6">
          {categoryName && categoryName.name}
          <span className="text-green-600 font-bold">
            {" "}
            - {subcategoryName && subcategoryName.name}
          </span>
        </h1> */}
      </div>

      <div
        className="Buttons lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-col
      items-center md:flex-row md:justify-around md:align-middle"
      >
        {/* Category */}
        <div className="category mx-2 my-3">
          <Select label="Category" name="selectedCategory" size="lg">
            {categories.map((category, index) => {
              return (
                <Option
                  key={index}
                  value={category.name}
                  onClick={() => {
                    handleCategoryClick(category);
                  }}
                >
                  {category.name}
                </Option>
              );
            })}
          </Select>

          {/* <Menu as="div" className="p-2 relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Category
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {categories.map((category, index) => {
                    return (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              console.log(category.name);
                              setcategoryName(category);
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {category.name}
                          </a>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu> */}
        </div>
        {/* Sub cat */}
        <div className="sub-cat mx-2 my-3">
          {/* <Menu as="div" className="p-2 relative inline-block text-left">
            <div>
              <Menu.Button
                className="inline-flex w-full justify-center gap-x-1.5
              rounded-md bg-white px-3 py-2 text-sm font-semibold 
              text-gray-900 shadow-sm ring-1 ring-inset
              ring-gray-300 hover:bg-gray-50"
              >
                Sub Category
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute left-0 z-10 mt-2 w-56 origin-top-right
              rounded-md bg-white shadow-lg ring-1 ring-black
              ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  {subCategories.map((subcat, index) => {
                    return (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              console.log(subcat.name);
                              setSubcategoryName(subcat);
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {subcat.name}
                          </a>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu> */}
          <Select label="Sub-category" name="selectedSubCategory" size="lg">
            {subCategories.map((subcat, index) => {
              return (
                <Option
                  key={index}
                  value={subcat.name}
                  onClick={() => {
                    console.log(subcat.name);
                    handleSubCategoryClick(subcat);
                  }}
                >
                  {subcat.name}
                </Option>
              );
            })}
          </Select>
        </div>

        <div
          className="my-2 md:my-0 md:m-1 px-2 h-10 md:h-12 w-2/6 md:w-1/6 
          flex flex-col justify-center items-center rounded-lg
        bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg
          hover:cursor-default transform transition-all hover:scale-110"
          onClick={() => {
            handleAddProductBtn();
          }}
        >
          <p className="hidden md:block text-center text-sm lg:text-md">
            Add Product
          </p>
          <IoIosAddCircleOutline className="md:hidden w-9" />
        </div>

        {/* Product  search */}
        <div className=" m-1 md:px-4 rounded-2xl hover:drop-shadow-lg md:w-3/6 h-11 hover:cursor-default">
          <Input
            label="Search"
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Typography
            variant="small"
            color="gray"
            className="mt-2 flex items-center gap-1 font-normal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="-mt-px h-4 w-4"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
            Search for product
          </Typography>
        </div>
      </div>

      <div className="md:hidden">
        <br />
      </div>

      <div
        className="lg:mx-12 md:mx-6 md:px-6 md:py-10 
        flex flex-row justify-center align-middle"
      >
        <table
          className="table-auto w-full border border-slate-500
        border-separate border-spacing-2"
        >
          <thead>
            <tr>
              <th className="p-2 border border-slate-600"> S.No</th>
              <th className="p-2 border border-slate-600">Name</th>
              <th className="p-2 hidden md:block border border-slate-600">
                Description
              </th>
              <th className="hidden md:block p-2 border border-slate-600">
                Applications
              </th>
              <th className="p-2 border border-slate-600">Image</th>
            </tr>
          </thead>
          <tbody>
            {products
              .filter((item) => {
                if (search == "") {
                  return item;
                } else if (
                  item.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return item;
                }
              })
              .map((item, index) => {
                return (
                  <tr key={index} className="text-sm md:text-base">
                    <td className="py-2 px-4 border border-slate-600">
                      {index + 1}
                    </td>
                    <td className="py-2 px-4 border border-slate-600">
                      {item.name}
                    </td>
                    <td className="hidden md:block py-2 mx-2 px-4 border border-slate-600">
                      {item.desc}
                    </td>
                    <td className="hidden md:block py-2 px-4 mx-2 border border-slate-600">
                      <ul className=" list-disc">
                        {item.feat.length > 0 &&
                          item.feat.map((feat, index) => {
                            return (
                              <li key={index} className="mx-2">
                                {feat}
                              </li>
                            );
                          })}
                      </ul>
                    </td>
                    <td className="p-4 border border-slate-600">
                      <img
                        // THIS LINE!!
                        src={item.img[0]}
                        alt="prod image"
                        className="object-contain w-48 h-48"
                        onClick={() => {
                          handleImgClickBtn(item);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddProducts;
