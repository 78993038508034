// NotFound.js
import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h1 className="text-4xl font-bold mb-4 text-red-500">404 Not Found</h1>
      <p className="text-lg text-gray-600 p-4 text-center">
        Oops! It seems like the page you are looking for does not exist.
      </p>
      {/*  link to the home page */}
      <a href="/" className="mt-4 text-blue-500 hover:underline">
        Go back to the Home Page
      </a>
    </div>
  );
};

export default NotFound;
