import React from "react";
import AboutCard from "./AboutCard";
import measure_img from "../../../assets/Measure.jpg";
import integrate_img from "../../../assets/Integrate.jpg";
import innovate_img from "../../../assets/Innovate.jpg";

const About = () => {
  return (
    <div className="2xl:container 2xl:mx-auto lg:pt-10 lg:pb-14 lg:px-20 md:py-8 md:px-6 pt-6 pb-9 px-6">
      <div className="w-full h-full">
        <div className="md:h-full flex flex-col justify-center items-center md:py-10 py-4">
          <h1
            className="container lg:pl-0 lg:pr-0 text-2xl 
          font-extrabold leading-10 tracking-tight
           mb-3 text-gray-90 sm:text-4xl sm:leading-10
            items-center text-center md:text-4xl 
            md:leading-normal"
          >
            About<span className=" pl-4 text-green-500 font-medium">Us</span>
          </h1>

          <p className=" text-justify font-kumbh font-normal py-6 px-2 text-gray-800 md:text-2xl text-lg tracking-normal md:leading-10">
            "Sun Teknolozy are the Pioneers in providing Solutions for all
            Environment Monitoring needs with our Core expertise of System
            Integration, Manufacturing, IoT Data Connectivity across Ambient,
            Industrial, Marine / Surface water & Pharma applications for the
            past 2 plus decades." We cater to variety of customer Environmental
            Monitoring requirements through our focused divisions for Air
            Quality, Water Quality, Industrial Health & Hygiene, and Pharma –
            Biotech.
          </p>
        </div>
      </div>
      <br />

      <div className="hidden md:block"></div>
      <div className=" w-full h-full">
        {/* <img src="" className="object-fill opacity-0 w-screen max-h-max" /> */}
        <div className="lg:h-full flex flex-col justify-center items-center">
          <h1
            className="container lg:pl-0 lg:pr-0 text-2xl font-extrabold leading-10 tracking-tight mb-3 
          text-gray-90 sm:text-4xl sm:leading-10 items-center text-center md:text-4xl md:leading-normal"
          >
            Our
            <span className=" pl-4 text-green-500 font-medium">Expertise</span>
          </h1>

          <div className="pt-12 grid sm:grid-cols-3 grid-cols-1 sm:gap-8 gap-4">
            {/* <!-- Measure Card --> */}
            <AboutCard imgSrc={measure_img} title="Measure" content={"What can’t be measured can’t be monitored"}/>
            {/* <!-- Integrate Card --> */}
            <AboutCard imgSrc={integrate_img} title="Integrate" content={"Integration simplifies and empowers monitoring"} />
            {/* <!-- Innovate Card --> */}
            <AboutCard imgSrc={innovate_img} title="Innovate" content={"Measuring, integrating and analysis thrives innovation"}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
