import React from "react";
import HeroItem from "./HeroItem";
import AliceCarousel from "react-alice-carousel";

// image imports
import firstSlide from "../../../assets/First_slide.jpg";
import cems from "../../../assets/cems2.png";
import insitu from "../../../assets/insitu.jpg";
import ldm from "../../../assets/ldm.png";
import oizom from "../../../assets/oizom.jpg";
import osil from "../../../assets/osil.jpg";
import ott from "../../../assets/ott.png";
import seabird from "../../../assets/seabird.jpg";
import toc from "../../../assets/toc.png";
import voc from "../../../assets/voc.png";
import firstSlide_mob from "../../../assets/First_slide_mob.jpeg";
import cems_mob from "../../../assets/cems_mob.png";
import insitu_mob from "../../../assets/insitu_mob.jpeg";
import ldm_mob from "../../../assets/ldm_mob.png";
import oizom_mob from "../../../assets/oizom_mob.jpeg";
import osil_mob from "../../../assets/osil_mob.jpeg";
import ott_mob from "../../../assets/ott_mob.png";
import seabird_mob from "../../../assets/seabird_mob.jpeg";
import toc_mob from "../../../assets/toc_mob.png";
import voc_mob from "../../../assets/voc_mob.png";

// Hero section
const Hero = () => {
  const heroData_mob = [
    <div>
      <HeroItem
        title=""
        bold_title=""
        desc1=""
        desc2=""
        desc3=""
        desc4=""
        desc5=""
        needTransparency={true}
        imgSrc={firstSlide_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="Sea-Bird"
        desc1="• Coastal & Marine Water Quality"
        desc2="• Profiling & Mooring"
        desc3="• Water Sampling, Carousel water samplers"
        desc4="• On-Board (Ship) Systems"
        desc5="• Satellite Ocean Data Validation"
        needTransparency={false}
        imgSrc={seabird_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="OTT"
        desc1="• Non-Contact Water Level Measurement"
        desc2="• Non-Contact Water Velocity cum discharge measurement"
        desc3="• Proven Dataloggers for Hydrology/Meteorology"
        desc4="• Urban Flood Monitoring"
        desc5=""
        needTransparency={false}
        imgSrc={ott_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="In-Situ"
        desc1="• Aquaculture Monitoring"
        desc2="• Ground Water Level"
        desc3="• Coastal, Fresh/Surface Water Quality"
        desc4="• Algal Bloom Monitoring"
        desc5=""
        needTransparency={false}
        imgSrc={insitu_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="OIZOM"
        desc1="• SMART Pole mounted AAQMS"
        desc2="• IoT Environmental Monitoring Solutions"
        desc3="• SMART/Flexible Automatic Weather Monitoring Station"
        desc4="• Industrial Fixed Gas Detectors"
        desc5=""
        needTransparency={false}
        imgSrc={oizom_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="OSIL"
        desc1="• Niskin Samplers"
        desc2="• Data Buoy Systems"
        desc3="• Sediment Grabs"
        desc4="• Salinometer, Salinity standards"
        desc5="• Marine Nutrient standards Kits"
        needTransparency={false}
        imgSrc={osil_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="TOC"
        desc1="• 680 Deg C catalytic combustion oxidation/NDIR method"
        desc2="• Measurement range: 4ppb to 30,000ppm with detection limit of 4ppb"
        desc3="• Measuring time: 3 to 4 minutes"
        desc4="• Automatic Sample Injection using microliter syringe without using Auto sampler"
        desc5="• Automatic with dilution factor of 2 to 50"
        needTransparency={false}
        imgSrc={toc_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="LDM"
        desc1="• Complies EPA Performance Spec PS1 CFR 40 Part 60"
        desc2="• Accommodates various stack diameters due to its wide laser travel path"
        desc3="• Event Logging, Multi-Stage Password protected"
        desc4="• Wide Operating Range"
        desc5="• Alert Notification: Via email (optional)"
        needTransparency={false}
        imgSrc={ldm_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="CEMS"
        desc1="• Extraction Type Continuous Emission Monitoring Systems"
        desc2="• Non Dispersive Infra-Red (NDIR) Spectroscopy"
        desc3="• Measures the quantity of SO2, NOx, CO, CO2, O2, etc…"
        desc4="• Event Logging, Multi-Stage Password protected"
        desc5="• Output: 4-20 mA current, 0-10 V voltage"
        desc6="• Communication Ports: RS232, RS485 (Modbus RTU)"
        needTransparency={false}
        imgSrc={cems_mob}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="VOC"
        desc1="• PID Method"
        desc2="• Patented fence Electrode Technology, Range: 0 to 100 PPM"
        desc3="• Power: 24 V DC, Output: 4/20mA"
        desc4="• ATEX Approved, intrinsically safe."
        desc5="• LEL, O2, H2S, CO, SO2, NO, NO2, H2, NH3, etc…"
        desc6="• Data logging and rechargeable battery"
        needTransparency={false}
        imgSrc={voc_mob}
      />
    </div>,
  ];

  const heroData = [
    <div>
      <HeroItem
        title=""
        bold_title=""
        desc1=""
        desc2=""
        desc3=""
        desc4=""
        desc5=""
        needTransparency={true}
        imgSrc={firstSlide}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="Sea-Bird"
        desc1="• Coastal & Marine Water Quality"
        desc2="• Profiling & Mooring"
        desc3="• Water Sampling, Carousel water samplers"
        desc4="• On-Board (Ship) Systems"
        desc5="• Satellite Ocean Data Validation"
        needTransparency={false}
        imgSrc={seabird}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="OTT"
        desc1="• Non-Contact Water Level Measurement"
        desc2="• Non-Contact Water Velocity cum discharge measurement"
        desc3="• Proven Dataloggers for Hydrology/Meteorology"
        desc4="• Urban Flood Monitoring"
        desc5=""
        needTransparency={false}
        imgSrc={ott}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="In-Situ"
        desc1="• Aquaculture Monitoring"
        desc2="• Ground Water Level"
        desc3="• Coastal, Fresh/Surface Water Quality"
        desc4="• Algal Bloom Monitoring"
        desc5=""
        needTransparency={false}
        imgSrc={insitu}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="OIZOM"
        desc1="• SMART Pole mounted AAQMS"
        desc2="• IoT Environmental Monitoring Solutions"
        desc3="• SMART/Flexible Automatic Weather Monitoring Station"
        desc4="• Industrial Fixed Gas Detectors"
        desc5=""
        needTransparency={false}
        imgSrc={oizom}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="OSIL"
        desc1="• Niskin Samplers"
        desc2="• Data Buoy Systems"
        desc3="• Sediment Grabs"
        desc4="• Salinometer, Salinity standards"
        desc5="• Marine Nutrient standards Kits"
        needTransparency={false}
        imgSrc={osil}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="TOC"
        desc1="• 680 Deg C catalytic combustion oxidation/NDIR method"
        desc2="• Measurement range: 4ppb to 30,000ppm with detection limit of 4ppb"
        desc3="• Measuring time: 3 to 4 minutes"
        desc4="• Automatic Sample Injection using microliter syringe without using Auto sampler"
        desc5="• Automatic with dilution factor of 2 to 50"
        needTransparency={false}
        imgSrc={toc}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="LDM"
        desc1="• Complies EPA Performance Spec PS1 CFR 40 Part 60"
        desc2="• Accommodates various stack diameters due to its wide laser travel path"
        desc3="• Event Logging, Multi-Stage Password protected"
        desc4="• Wide Operating Range"
        desc5="• Alert Notification: Via email (optional)"
        needTransparency={false}
        imgSrc={ldm}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="CEMS"
        desc1="• Extraction Type Continuous Emission Monitoring Systems"
        desc2="• Non Dispersive Infra-Red (NDIR) Spectroscopy"
        desc3="• Measures the quantity of SO2, NOx, CO, CO2, O2, etc…"
        desc4="• Event Logging, Multi-Stage Password protected"
        desc5="• Output: 4-20 mA current, 0-10 V voltage"
        desc6="• Communication Ports: RS232, RS485 (Modbus RTU)"
        needTransparency={false}
        imgSrc={cems}
      />
    </div>,
    <div>
      <HeroItem
        title=""
        bold_title="VOC"
        desc1="• PID Method"
        desc2="• Patented fence Electrode Technology, Range: 0 to 100 PPM"
        desc3="• Power: 24 V DC, Output: 4/20mA"
        desc4="• ATEX Approved, intrinsically safe."
        desc5="• LEL, O2, H2S, CO, SO2, NO, NO2, H2, NH3, etc…"
        desc6="• Data logging and rechargeable battery"
        needTransparency={false}
        imgSrc={voc}
      />
    </div>,
  ];
  return (
    <section className="text-gray-600 body-font bg-white dark:bg-slate-900">
      <div className="hidden md:block">
        <AliceCarousel
          autoPlay="true"
          autoPlayStrategy="none"
          animationType="slide"
          infinite = "true"
          autoPlayInterval={2000}
          animationDuration={3000}
          items={heroData}
          controlsStrategy="alternate"
          disableDotsControls="true"
        />
        {/* <div class="lg:flex-grow mt-20 md:mt-0 md:w-1.5/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center sm:items-start sm:text-left">
          <div class="container pl-2 pr-2 lg:pl-0 lg:pr-0 flex flex-row justify-start items-start">
                <a href="#"
                    class="ml-0.5 flex  text-gray-700 focus:outline-none hover:bg-gray-200 rounded text-lg">Find out <p className='text-orange-700 font-medium pl-2'>more.</p> </a>
           
            </div>
        </div> */}
        {/* Hero Image */}
      </div>
      
      <div className="md:hidden">
        <AliceCarousel
          autoPlay="true"
          autoPlayStrategy="none"
          animationType="slide"
          infinite = "true"
          autoPlayInterval={2000}
          animationDuration={3000}
          items={heroData_mob}
          controlsStrategy="alternate"
          disableDotsControls="true"
        />
        </div>
    </section>
  );
};

export default Hero;
