const BASE_URL = "https://server.sunteknolozy.in/api/";
// const BASE_URL = "https://stserver-7twz.onrender.com/api/";
// const BASE_URL = "http://localhost:3001/api/";
export const FETCH_CATEGORIES_API = `${BASE_URL}categories`;
export const FETCH_CATEGORY_DETAIL_API = `${BASE_URL}category/`;
export const FETCH_PRODUCTS_BY_INDEX_API = `${BASE_URL}products/`;
export const FETCH_PRODUCT_DETAIL_API = `${BASE_URL}product/`;
export const FETCH_SUBCATEGORIES_API = `${BASE_URL}subcategories/`;
export const FETCH_SUBCATEGORY_PRODUCTS_API = `${BASE_URL}products/`;
export const UPLOAD_PRODUCT_API = `${BASE_URL}uploadproduct/`;
export const EDIT_PRODUCT_API = `${BASE_URL}editproduct/`;
export const DELETE_PRODUCT_API = `${BASE_URL}deleteproduct/`;
export const DELETE_CLIENT_API = `${BASE_URL}deleteclient/`;
export const LOGIN_API = `${BASE_URL}login`;
export const AUTH_API = `${BASE_URL}protected`;
export const UPLOAD_CLIENT_API = `${BASE_URL}uploadclient/`;
export const ADD_CATEGORY_API = `${BASE_URL}addcategory`;
export const DELETE_CATEGORY_API = `${BASE_URL}deletecategory/`;
export const ADD_SUBCATEGORY_API = `${BASE_URL}addsubcategory`;
export const DELETE_SUBCATEGORY_API = `${BASE_URL}deletesubcategory/`;
export const ALL_PRODUCTS_API = `${BASE_URL}search`;
export const UPLOAD_MANUAL_API = `${BASE_URL}uploadmanual/`;
export const FETCH_ALL_MANUAL_API = `${BASE_URL}listmanual/`;
export const DELETE_MANUAL_API = `${BASE_URL}deletemanual/`;
export const FETCH_MANUAL_API = `${BASE_URL}downloadmanual/`;
export const FETCH_CLIENTS_API = `${BASE_URL}clients`;
export const FETCH_TESTIMONIAL_API = `${BASE_URL}testimonials`;
export const ADD_TESTIMONIAL_API = `${BASE_URL}uploadtestimonial`;
export const DELETE_TESTIMONIAL_API = `${BASE_URL}deletetestimonial/`;
export const CONTACT_US_API = `${BASE_URL}contactus`;
export const RAISE_CASE_API = `${BASE_URL}raisecase`;
export const ENQUIRE_NOW_API = `${BASE_URL}enquire`;
export const RAISE_ALERT_API = `${BASE_URL}raisealert`;