import React from "react";

const ErrorMsg = ({ errMsg, onClose }) => {
  console.log("Error message: ", errMsg);
  const handleCancel = () => {
    onClose();
  };
  return (
    <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-xl shadow-md">
        {/* <h2 className="text-lg font-medium mb-4">{errMsg}</h2> */}
        {errMsg.split(".").map((err, index) => {
          return (
            <h2 className="text-lg font-medium mb-4" key={index}>
              {err}
            </h2>
          );
        })}
        <br />

        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorMsg;
