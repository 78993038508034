import React from "react";
import { Link } from "react-router-dom";

const BusinessVerticalCard = ({ imgSrc, title , link}) => {
  return (
    <Link to={link}>
      <div className="relative w-70 h-72 transform transition-all hover:scale-110 rounded-2xl">
      <img src={imgSrc} alt={title} className="object-cover w-70 h-72 rounded-2xl" />
      <div className="absolute inset-0 flex flex-col justify-center items-center bg-opacity-40 bg-gray-900 rounded-2xl">
        <h1 className="container text-center text-lg font-bold leading-10 mb-3 text-white dark:text-gray-100 sm:text-md sm:leading-5 md:text-xl md:leading-relaxed ">
          {title}
        </h1>-
      </div>
    </div>
    </Link>
  );
};

export default BusinessVerticalCard;
