const loginFields = [
    {
      labelText: "Email address",
      labelFor: "email-address",
      id: "email-address",
      name: "email",
      type: "email",
      autoComplete: "email",
      isRequired: true,
      placeholder: "",
      label: "Email"
    },
    {
      labelText: "Password",
      labelFor: "password",
      id: "password",
      name: "password",
      type: "password",
      autoComplete: "current-password",
      isRequired: true,
      placeholder: "",
      label: "Password"
    },
    // Input field for user's name
    {
      labelText: "Name",
      labelFor: "name",
      id: "name",
      name: "name",
      type: "text",
      autoComplete: "name",
      isRequired: true,
      placeholder: "",
      label: "Name"
    },
    //Input fields for user's reason for logging in
    {
      labelText: "Reason",
      labelFor: "reason",
      id: "reason",
      name: "reason",
      type: "text",
      autoComplete: "reason",
      isRequired: true,
      placeholder: "",
      label: "Reason"
    },
  ];

  export {loginFields};