import React from "react";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
const ProductItem = ({ imageUrl, title, desc, feat, productUrl }) => {
  return (
    <div className="p-2 m-2 transform transition-all hover:scale-110">
      <Link to={`/products/${productUrl}`}>
        <img src={imageUrl} alt="Item" className="object-cover w-44 h-40" />
        <div className=" flex flex-col">
          <p className="md:pr-0 text-gray-900 hover:text-green-500 pr-16 pt-5 text-base lg:text-lg font-medium">
            {title}
          </p>
          {/* {desc.length > 55 ? (
          <p className="pt-4 pb-2 text-md font-normal">
            {desc.trim().substring(0, 55 ) + "...."}
          </p>
        ) : (
          <p className="pt-4 pb-2 font-normal">{desc}</p>
        )} */}
          {/* hidden product feature due to updated requirement */}
          {/* <p className="text-sm text-gray-800 md:text-base font-kumbh font-light">{feat[0]}</p> */}
          {/* <div className="py-4">
          <Button color="green" variant="gradient" size="sm" className="mb-2">
            <Link to={`/products/${productUrl}`}>
              <span className="py-2">Know more</span>
            </Link>
          </Button>
        </div> */}
        </div>
      </Link>
    </div>
  );
};

export default ProductItem;
