import { Input } from "@material-tailwind/react";
import React, { useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { MdOutlineDelete } from "react-icons/md";
import { ADD_TESTIMONIAL_API } from "../../Api";
import axios from "axios";
import ErrorMsg from "../AddProductComponents/ErrorMsg";

const AddTestimonial = ({ onClose }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [organization, setOrganization] = useState("");
  const [designation, setDesignation] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgPopup, setErrorMsgPopup] = useState(false);
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleSubmit = async () => {
    console.log("Submit button clicked");
    if (image === null || name === "" || description === "" || organization === "" || designation === "") {
      setErrorMsg("Please fill all the fields");
      setErrorMsgPopup(true);
      return;
    }
    const formData = new FormData();
    formData.append("testimonialName", name);
    formData.append("testimonialDesgination", designation);
    formData.append("testimonialOrganization", organization);
    formData.append("testimonialDescription", description);
    formData.append("testimonialImage", image);
    try {
      const response = await axios.post(ADD_TESTIMONIAL_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        console.log("File uploaded successfully");
        onClose();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMsg("Error uploading file");
      setErrorMsgPopup(true);
    }
  };
  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
  };
  const handleOrganizationChange = (e) => {
    setOrganization(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleImageRemoval = () => {
    setImage(null);
  };
  const handleCancel = () => {
    onClose();
  };
  return (
    <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-xl shadow-md">
        {errorMsgPopup && (
          <ErrorMsg
            errMsg={errorMsg}
            onClose={() => {
              setErrorMsgPopup(false);
            }}
          />
        )}
        <h2 className="text-lg font-semibold mb-4">Add Testimonial</h2>
        <Input
          type="text"
          required
          label="Name"
          onChange={handleNameChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />
        <Input
          type="text"
          required
          size="lg"
          label="Desgination"
          onChange={handleDesignationChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />
        <Input
          type="text"
          required
          size="lg"
          label="Organization"
          onChange={handleOrganizationChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />
        <Input
          type="text"
          required
          size="lg"
          label="Description"
          onChange={handleDescriptionChange}
          className="border border-gray-300 p-2 w-full"
        />
        <br />
        <label className="text-xs md:text-md text-gray-600 ml-2">Image <span className=" text-red-600 pl-0.5"> *</span></label>
        <div
          className="image-preview grid grid-cols-2 gap-2
            md:grid-cols-3 lg:grid-cols-5 md:gap-4"
        >

            {image && (
              <div className="relative inline-block mb-4">
                <img
                  src={URL.createObjectURL(image)}
                  alt="Product"
                  className="w-20 h-20 m-1 p-1 object-cover rounded-xl"
                />
                <button
                  onClick={() => {
                    handleImageRemoval();
                  }}
                  className=" w-4 h-4 bg-white
                    absolute top-0 right-0 -mr-1 md:mr-0
                    text-white p-2 rounded-full"
                >
                  <MdOutlineDelete
                    className="text-red-500
                        absolute top-0 right-0 "
                  />
                </button>
              </div>
            )}
            <label className="text-xs md:text-md text-gray-600">
              <div className="h-full flex justify-start items-center">
                <div className="relative mt-2">
                  <input
                    type="file"
                    id="image"
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                    className="sr-only"
                  />
                  <div
                    htmlFor="image"
                    className="cursor-pointer hover:bg-gray-500
               bg-gray-300 w-14 h-14 m-1 p-1 text-white px-4 
                flex justify-center items-center rounded-full"
                  >
                    <IoIosAdd className="w-10 h-10 text-gray-800" />
                  </div>
                </div>
              </div>
            </label>

            {/* <img
            src="https://images.unsplash.com/photo-1702573849838-0f66f3731fdf?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className=" m-1 p-1 w-20 h-20 object-cover"
          /> */}


          {/* <img
            src="https://images.unsplash.com/photo-1702573849838-0f66f3731fdf?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className=" m-1 p-1 w-20 h-20 object-cover"
          /> */}
        </div>
        <br />
        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded mr-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTestimonial;
