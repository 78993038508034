import React, { useEffect, useRef, useState } from "react";
import NewNav from "../navbar/NewNav";

import { CiChat1 } from "react-icons/ci";
import { CiSquareInfo } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { MdMarkEmailRead } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";
import { PiPhoneDisconnectBold } from "react-icons/pi";

// images import
import bangaloreImg from "../../assets/bangalore.jpeg";
import ariyalurImg from "../../assets/ariyalur.jpeg";
import coimbatoreImg from "../../assets/coimbatore.jpeg";
import tuticorinImg from "../../assets/tuticorin.jpeg";

import contactUs from "../../assets/contactUs.jpg";

import Address from "../address/Address";
import { Button, Input, Select, Option } from "@material-tailwind/react";

// import { db } from "../../firebase";
// import { collection, getDocs } from "firebase/firestore";

// import emailjs from "@emailjs/browser";
import SuccessMsg from "../adminPage/AddProductComponents/SuccessMsg";
import RaiseCase from "./RaiseCase";
import ErrorMsg from "../adminPage/AddProductComponents/ErrorMsg";
import { CONTACT_US_API, FETCH_CATEGORIES_API } from "../Api";
import axios from "axios";

const Contact = () => {
  const [categories, setCategories] = useState([]); // Categories data
  const tempCategoryData = [];
  const [category, setCategory] = useState(null); // Category selected by user
  const [popUpMsg, setPopUpMsg] = useState(false); // Success Pop up message
  const [openRaiseCase, setOpenRaiseCase] = useState(false); // Raise a case form

  const [orgName, setOrgName] = useState("");
  const [personName, setPersonName] = useState("");
  const [orgContactNumber, SetOrgContactNumber] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [description, setDescription] = useState("");
  // const [recipientEmail, setRecipientEmail] = useState(""); // Email of the selected category
  const [errPopUpMsg, setErrPopUpMsg] = useState(false); // Error Pop up message
  const [errorMsg, setErrorMsg] = useState(""); // Error message
  const form = useRef();
  const scrollToMiddle = () => {
    window.scrollTo({
      top: 1000,
      behavior: "smooth",
    });
  };
  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value);
  };
  const handlePersonNameChange = (e) => {
    setPersonName(e.target.value);
  };
  const handleOrgContactNumberChange = (e) => {
    SetOrgContactNumber(e.target.value);
  };
  const handleEmailChange = (e) => {
    setOrgEmail(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryDropDwon = (categoryObj, index) => {
    setCategory(categoryObj);
  };

  const handleSubmit = async (e) => {
    var tempErrMsg = "";
    e.preventDefault();
    console.log("Submit button clicked");
    // check if all fields are filled one at a time
    if (orgName === "") {
      tempErrMsg = tempErrMsg + "Please fill Organization Name field. \t\n";
    }
    if (personName === "") {
      tempErrMsg = tempErrMsg + "Please fill Person Name field. \t\n";
    }
    if (
      orgContactNumber === "" ||
      orgContactNumber.length < 10 ||
      orgContactNumber.length > 10
    ) {
      tempErrMsg = tempErrMsg + "Please Check Contact Number field. \t\n";
    }
    if (
      orgEmail === "" ||
      !orgEmail.includes("@") ||
      !orgEmail.includes(".") ||
      orgEmail.includes(" ")
    ) {
      tempErrMsg = tempErrMsg + "Please Check Email field. \t\n";
    }
    if (description === "") {
      tempErrMsg = tempErrMsg + "Please fill Description field. \t\n";
    }
    if (category === null) {
      tempErrMsg = tempErrMsg + "Please select a category. \t\n";
    }
    if (tempErrMsg !== "") {
      console.log("Error message: ", tempErrMsg);
      setErrorMsg(tempErrMsg);
      setErrPopUpMsg(true);
      return;
    }

    // emailjs
    //   .sendForm(
    //     "service_m6ic03v",
    //     "template_djzf49d",
    //     form.current,
    //     "PSCaJnSfdxLHUsDFI"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       setPopUpMsg(true);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    const formData = new FormData();
    formData.append("orgName", orgName);
    formData.append("personName", personName);
    formData.append("orgEmail", orgEmail);
    formData.append("orgContactNumber", orgContactNumber);
    if (category) {
      formData.append("category", category.name);
    } else {
      formData.append("category", "Not selected");
    }

    formData.append("description", description);
    try {
      const response = await axios.post(CONTACT_US_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status >= 200) {
        setPopUpMsg(true);
      } else {
        console.log("Error in sending mail");
      }
    } catch (error) {
      console.log("Error in sending mail", error);
    }
  };

  // async function fetchCategoriesFromFirestore() {
  //   const categoriesCollection = collection(db, "Categories"); // Reference to the 'categories' collection in Firestore

  //   try {
  //     const querySnapshot = await getDocs(categoriesCollection); // Fetch all documents in the 'products' collection

  //     querySnapshot.forEach((item) => {
  //       // console.log(item);
  //       const categoryData = item.data();
  //       // console.log(categoryData.name);
  //       tempCategoryData.push(categoryData);
  //     });
  //     setCategories(tempCategoryData);
  //     // Set the categories data in state
  //   } catch (error) {
  //     console.error("Error fetching category list", error);
  //   }
  // }
  async function fetchCategoriesFromServer() {
    try {
      const response = await fetch(FETCH_CATEGORIES_API);
      const categoriesData = await response.json();
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }

  useEffect(() => {
    fetchCategoriesFromServer(); // Fetch categories when the component mounts
  }, []);

  return (
    <div>
      <NewNav />
      {
        // Error pop up message
        errPopUpMsg && (
          <ErrorMsg
            errMsg={errorMsg}
            onClose={() => {
              setErrPopUpMsg(false);
            }}
          />
        )
      }
      {popUpMsg && (
        <SuccessMsg
          msg="Form submission successful! We will contact you soon."
          onClose={() => {
            setPopUpMsg(false);
          }}
        />
      )}
      {
        // Raise a case form
        openRaiseCase && (
          <RaiseCase
            onCancel={() => {
              setOpenRaiseCase(false);
            }}
          />
        )
      }
      <div className="Contact US">
        <div className="hero-section relative w-full h-screen">
          <img
            src={contactUs}
            alt=""
            className="object-cover w-screen h-screen"
          />
          <div
            className=" h-screen
          bg-opacity-50 bg-black
          upper-section absolute inset-0 flex flex-col justify-evenly"
          >
            <div
              className="h-fit 2xl:container 2xl:mx-auto lg:py-12 lg:px-20 md:px-6 px-6
            flex flex-col justify-center items-center md:py-10 py-4"
            >
              <h1
                className="container text-white lg:pl-0 lg:pr-0 text-3xl 
              font-extrabold leading-10 tracking-tight mb-3 text-gray-90
              sm:text-4xl sm:leading-10 items-center text-center md:text-5xl
              md:leading-normal"
              >
                Service &
                <span className=" pl-4 text-green-500 font-medium">
                  Support
                </span>
              </h1>

              <p className=" text-justify font-kumbh font-normal py-6 px-2 text-gray-300 md:text-2xl text-lg tracking-normal md:leading-10">
                Sun Teknolozy are the Pioneers in providing Solutions for all
                Environment Monitoring needs with our Core expertise of System
                Integration, Manufacturing, IoT Data Connectivity across
                Ambient, Industrial & Marine / Surface water & Pharma
                applications for the past 2 plus decades. We cater to variety of
                customer Environmental Monitoring requirements through our
                focused divisions for Air Quality, Water Quality, Industrial
                Health & Hygiene, and Pharma – Biotech.
              </p>
            </div>
            {/* 2 buttons Section */}
            <div className="flex w-full flew-row justify-evenly align-middle">
              <div
                className={`
                    w-1/3 m-1 px-2 py-2 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg h-auto hover:cursor-default transform transition-all hover:scale-110`}
                onClick={() => {
                  scrollToMiddle();
                }}
              >
                {" "}
                <div className="flex flex-col justify-center items-center">
                  <CiChat1 className=" w-9 h-9" />
                  <p className="text-center text-md"> Enquire now</p>
                </div>
              </div>

              <div
                className={`
                    w-1/3 m-1 px-2 py-2 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg h-auto hover:cursor-default transform transition-all hover:scale-110`}
                onClick={() => {
                  // handleDivisionChange(division, index);
                }}
              >
                {" "}
                <div
                  onClick={() => {
                    setOpenRaiseCase(!openRaiseCase);
                  }}
                  className="flex flex-col justify-center items-center"
                >
                  <CiSquareInfo className=" w-9 h-9" />
                  <p className="text-center text-md">Raise a Case</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Contact Us Section */}
        <div className="Contact-us 2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-6">
          <div className="flex flex-col justify-center items-center md:py-10 py-4">
            <h1 className="container lg:pl-0 lg:pr-0 text-2xl font-extrabold leading-10 tracking-tight mb-3 md:pb-3 text-gray-90 sm:text-4xl sm:leading-10 items-center text-center md:text-4xl md:leading-normal">
              Contact
              <span className=" pl-4 text-green-500 font-medium">Us</span>
            </h1>
          </div>
          <div className="flex md:flex-row md:justify-between flex-col justify-normal">
            {/* Contact info */}
            <div className=" contact-info flex flex-col justify-start items-start">
              <div className="pb-2">
                <h2 className="text-lg md:text-xl font-semibold mb-4">Sales</h2>

                <div className="flex flex-row py-2">
                  <MdMarkEmailRead className=" w-6 h-6" />{" "}
                  <p className="text-md md:text-lg pl-2 text-gray-700">
                    {" "}
                    admin@sunteknolozy.com
                  </p>
                </div>
                <div className="flex flex-row py-2">
                  <CiPhone className="w-6 h-6" />
                  <p className=" pl-2 text-md md:text-lg text-gray-700">
                    {" "}
                    +91 9790935128
                  </p>
                </div>
                <div className="flex flex-row py-2">
                  <PiPhoneDisconnectBold className="w-6 h-6" />
                  <p className="pl-2 text-md md:text-lg text-gray-700">
                    {" "}
                    044 - 35661951{" "}
                  </p>
                </div>
              </div>
              <div className="py-2">
                <h2 className=" text-lg md:text-xl font-semibold mb-4">
                  Service
                </h2>
                <div className="flex flex-row py-2">
                  <MdMarkEmailRead className=" w-6 h-6" />{" "}
                  <p className="text-md md:text-lg pl-2 text-gray-700">
                    {" "}
                    service@sunteknolozy.com
                  </p>
                </div>
                <div className="flex flex-row py-2">
                  <CiPhone className="w-6 h-6" />
                  <p className=" pl-2 text-md md:text-lg text-gray-700">
                    {" "}
                    +91 7598725128
                  </p>
                </div>

                <div className="flex flex-row py-2">
                  <PiPhoneDisconnectBold className="w-6 h-6" />
                  <p className="pl-2 text-md md:text-lg text-gray-700">
                    {" "}
                    044 - 35661951{" "}
                  </p>
                </div>
              </div>
              <div className="py-2">
                <h2 className=" text-lg md:text-xl font-semibold mb-4">
                  Address
                </h2>
                <div className="flex flex-col py-2">
                  <FaMapMarkedAlt className="w-6 h-6" />
                  <p className="py-3 text-md md:text-lg text-gray-700">
                    {" "}
                    “STUDIO - 10”, Vasavi Street,
                    <br />
                    Plot No. 32 & 33, Kamala Nagar, <br />
                    Kelambakkam, Chennai-603103, <br /> Tamil Nadu, India
                  </p>
                </div>
              </div>
            </div>
            <br />
            {/* Contact Us Form */}
            <div className="flex flex-col">
              <h2 className="text-md md:text-xl font-semibold mb-4">
                Please Fill out this form,
                <span className="text-green-500">
                  {" "}
                  Our team will contact you soon!{" "}
                </span>
              </h2>
              <br />
              <div className="contact-us">
                <form id="enquiry-form" ref={form}>
                  <Input
                    required
                    name="orgName"
                    type="text"
                    label="Organization Name"
                    onChange={handleOrgNameChange}
                    className="border border-gray-300 p-2 w-full"
                  />
                  <br />
                  <Input
                    required
                    name="personName"
                    type="text"
                    label="Your Name"
                    onChange={handlePersonNameChange}
                    className="border border-gray-300 p-2 w-full"
                  />
                  <br />

                  <Input
                    required
                    name="orgContactNumber"
                    type="number"
                    label="Contact Number"
                    onChange={handleOrgContactNumberChange}
                    className="border border-gray-300 p-2 w-full"
                  />
                  <br />
                  <Input
                    required
                    name="orgEmail"
                    type="email"
                    label="E-mail"
                    onChange={handleEmailChange}
                    className="border border-gray-300 p-2 w-full"
                  />
                  <br />
                  <Select
                    label="Select Category (required)"
                    name="selectedCategory"
                  >
                    {categories.map((category, index) => (
                      <Option
                        key={index}
                        onClick={() => {
                          handleCategoryDropDwon(category, index);
                        }}
                        value={category.name}
                      >
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                  <br />
                  {/* {category && (
                    <input
                      name="sendTo"
                      type="text"
                      value="afzaldev2@gmail.com" //{category.salesEmail}
                      className="hidden"
                    />
                  )} */}
                  <div>
                    <div className="relative w-full min-w-[200px]">
                      <textarea
                        className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
                        placeholder=" "
                        name="description"
                        type="text"
                        required
                        onChange={handleDescriptionChange}
                      ></textarea>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Description / Usecase{" "}
                        <span className=" text-red-600 pl-0.5"> *</span>
                      </label>
                    </div>
                  </div>
                  {/* <textarea
                    name="description"
                    type="text"
                    placeholder="Description / Usecase"
                    required
                    rows={4}
                    onChange={handleDescriptionChange}
                    className=" rounded-lg border border-gray-400 text-md text-gray-600 p-2 mb-4 w-full"
                  /> */}
                  <br />
                  <div className="flex justify-end">
                    <Button
                      size="lg"
                      className="px-4 py-2 w-full bg-green-500 text-white rounded"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row md:justify-evenly flex-col justify-normal items-center">
            {/* Map info */}
            <div className="lg:w-1/2 my-3 py-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d249013.41454043676!2d79.9090307!3d12.7892458!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267030c426185%3A0x98020b7b1e16afc2!2sSUN%20TEKNOLOZY!5e0!3m2!1sen!2sin!4v1704023524340!5m2!1sen!2sin"
                // width={600}
                // height={450}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="px-6 md:px-0 h-64 lg:h-96 lg:w-96"
                title="Sun Teknolozy Map"
              ></iframe>
            </div>
            {/* Service outlets */}
            <div className="mt-2 w-1/2 md:py-10 rounded-xl">
              <h2 className="text-xl md:text-2xl text-center font-semibold mb-4">
                Service <span className="text-green-500">Outlets</span>
              </h2>
              <div className="">
                <ul
                  className="flex flex-col justify-center 
                items-center py-2 text-xl font-kumbh"
                >
                  {/* <li className=" py-2">Ariyalur</li>
                  <li className=" py-2">Tuticorin</li>
                  <li className=" py-2">Coimbatore</li>
                  <li className=" py-2">Bengaluru</li> */}

                  {/* Service outlets cards*/}
                  <div className="lg:flex">
                    {/* Ariyalur */}
                    <div className="my-1 p-2">
                      <div className=" w-70 h-28 relative transform transition-all hover:scale-110 rounded-2xl">
                        <img
                          src={ariyalurImg}
                          alt={"Ariyalur"}
                          className="object-cover w-70 h-28 rounded-2xl"
                        />
                        <div className="absolute inset-0 flex justify-center items-center bg-opacity-40 bg-gray-900 rounded-2xl">
                          <h1
                            className="container text-center text-lg font-bold leading-10 text-white dark:text-gray-100
                         sm:text-md sm:leading-5 md:text-xl md:leading-relaxed "
                          >
                            {"Ariyalur"}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {/* Bangalore */}
                    <div className="my-1 p-2">
                      <div className=" w-70 h-28 relative transform transition-all hover:scale-110 rounded-2xl">
                        <img
                          src={bangaloreImg}
                          alt={"Bangalore"}
                          className="object-cover w-70 h-28 rounded-2xl"
                        />
                        <div className="absolute inset-0 flex justify-center items-center bg-opacity-40 bg-gray-900 rounded-2xl">
                          <h1
                            className="container text-center text-lg font-bold leading-10 text-white dark:text-gray-100
                         sm:text-md sm:leading-5 md:text-xl md:leading-relaxed "
                          >
                            {"Bangalore"}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {/* Tuticorin */}
                    <div className="my-1 p-2">
                      <div className=" w-70 h-28 relative transform transition-all hover:scale-110 rounded-2xl">
                        <img
                          src={tuticorinImg}
                          alt={"Tuticorin"}
                          className="object-cover w-70 h-28 rounded-2xl"
                        />
                        <div className="absolute inset-0 flex justify-center items-center bg-opacity-40 bg-gray-900 rounded-2xl">
                          <h1
                            className="container text-center text-lg font-bold leading-10 text-white dark:text-gray-100
                         sm:text-md sm:leading-5 md:text-xl md:leading-relaxed "
                          >
                            {"Tuticorin"}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {/* Coimbatore */}
                    <div className="my-1 p-2">
                      <div className=" w-70 h-28 relative transform transition-all hover:scale-110 rounded-2xl">
                        <img
                          src={coimbatoreImg}
                          alt={"Coimbatore"}
                          className="object-cover w-70 h-28 rounded-2xl"
                        />
                        <div className="absolute inset-0 flex justify-center items-center bg-opacity-40 bg-gray-900 rounded-2xl">
                          <h1
                            className="container text-center text-lg font-bold leading-10 text-white dark:text-gray-100
                         sm:text-md sm:leading-5 md:text-xl md:leading-relaxed "
                          >
                            {"Coimbatore"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Address />
    </div>
  );
};

export default Contact;
